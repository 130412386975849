import './App.css';
import { BrowserRouter , Route, Routes } from "react-router-dom";
import Signin from './components/Signin'
import Signup from './components/Signup'
import Landing from './components/Landing';
import Profile from './components/Profile';
import Payment from './components/Payment';
import CreateToken from './components/CreateToken';
import DeployedToken from './components/DeployedToken';
import DeployedNft from './components/DeployedNft';
import CreateNft from './components/CreateNft';
import GenerateApiKeys from './components/Api Keys/GenerateApiKeys';
import Mintable from './components/Mintable';
import TokenInfo from './components/TokenInfo';
import ApiList from './components/ApiList';
import GenerateApi from './components/GenerateApi';
import PaymentApi from './components/PayemntApi';
import ForgotPassword from './components/ForgotPassword';
import PasswordRecovery from './components/PasswordRecovery';
import Verification from './components/Verification';
import TokenDescription from './components/TokenDescription'
import DescriptionPayment from './components/DescriptionPayment'
function App() {
  return (


    <BrowserRouter>
    <Routes>
  <Route path ="/signin" element={<Signin/>} />
  <Route path ="/signup" element={<Signup/>} />
  <Route path ="/" element={<Landing/>} />
  <Route path ="/profile" element={<Profile/>} />
  <Route path ="/payment" element={<Payment/>} />
  <Route path ="/createtoken" element={<CreateToken/>} />
  <Route path ="/user-deployed-token" element={<DeployedToken/>} />
  <Route path ="/user-deployed-nft" element={<DeployedNft/>} />
  <Route path ="/createnft" element={<CreateNft/>} />
  <Route path ="/generate-api-keys" element={<GenerateApi/>} />
  <Route path ="/mintable" element={<Mintable/>} />
  <Route path ="/tokeninfo" element={<TokenInfo/>} />
  <Route path ="/api-list" element={<ApiList/>} />
  <Route path ="/payment-api" element={<PaymentApi/>} />
  <Route path ="/forgot-password" element={<ForgotPassword/>} />
  <Route path ="/password-recovery" element={<PasswordRecovery/>} />
  <Route path="/token-description" element={<TokenDescription/>} />
  <Route path="/description-payment" element={<DescriptionPayment/>} />
  <Route path="*" element={<Verification/>} />
    </Routes>
  </BrowserRouter>

    );

  }

  export default App;
