import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form, Row, Col , Navbar , Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import Header2 from './Header/Header2';
import Footer from './Footer';
import ercbtn from '../Images/ercbtn.png'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
export default class TokenInfo extends Component {
 
    render() {       
        return (
           
            <>
            <div className="profile">
            <Header2/>
            <br/>
            <h1 className="token">Database Relation</h1>
            <p style={{fontSize:'20px'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
            <div className="cardWhite" style={{borderRadius:'.25rem',paddingLeft:'3%',paddingRight:'3%',paddingTop:'1%',paddingBottom:'1%',fontSize:'18px',color:'black'}}>
            <i className="fa fa-exclamation-triangle" style={{color:'red',fontSize:'20px',marginRight:'10px'}}></i>
            <span style={{color:'red',fontWeight:'600',marginRight:'5px'}}>Alert</span>
            <span>: To use this app please install <Link to="" style={{fontWeight:'500',color:'#3F1585'}}>AIFI WALLET</Link>. Use any other wallet at your own risk.</span>
            </div>
            <div className="Pycenter">
               
                <div className="CardP">
 
                    <div className=" pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>
                 
                    <span style={{color:'#3F1585',fontWeight:'700',fontSize:'32px'}}>Overview<span style={{color:"#888",fontSize:"28px",fontWeight:"500"}}> [AIFI] </span></span>
                   
                    </div>
                   
 
                    <div className="row mt-5 ">
                        <div className="col-md-6 col-sm-12">
                        <form>
                            <div className="form-group">
                                 <label for="exampleInputEmail1" style={{fontSize:'18px',fontWeight:'500'}}>Token Name :</label>
                            </div>
                        </form>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <form>
                        <div className="form-group">
                         <label for="exampleInputEmail1"  style={{fontSize:'18px',fontWeight:'500',color:"#C6C6C6"}}>XYZ</label>
                         </div>
                        </form>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <form>
                            <div className="form-group">
                                 <label for="exampleInputEmail1" style={{fontSize:'18px',fontWeight:'500'}}>Token Symbol :</label>
                            </div>
                        </form>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <form>
                        <div className="form-group">
                         <label for="exampleInputEmail1"  style={{fontSize:'18px',fontWeight:'500',textAlign:"right",color:"#C6C6C6"}}>AIFI</label>
                         </div>
                        </form>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <form>
                            <div className="form-group">
                                 <label for="exampleInputEmail1" style={{fontSize:'18px',fontWeight:'500'}}>Total Supply :</label>
                            </div>
                        </form>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <form>
                        <div className="form-group">
                         <label for="exampleInputEmail1"  style={{fontSize:'18px',fontWeight:'500',textAlign:"right",color:"#C6C6C6"}}>1,00,000.00</label>
                         </div>
                        </form>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <form>
                            <div className="form-group">
                                 <label for="exampleInputEmail1" style={{fontSize:'18px',fontWeight:'500'}}>Decimal :</label>
                            </div>
                        </form>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <form>
                        <div className="form-group">
                         <label for="exampleInputEmail1"  style={{fontSize:'18px',fontWeight:'500',textAlign:"right",color:"#C6C6C6"}}>18</label>
                         </div>
                        </form>
                        </div>
                    </div>
 
                 
                   
                </div>
 
 
                <div className="CardP">
 
<div className=" pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>
 
<span style={{color:'#3F1585',fontWeight:'700',fontSize:'32px'}}>Token Details</span>
 
</div>
 
 
<div className="row mt-5 ">
    <div className="col-md-12 col-sm-12">
    <form>
        <div className="form-group">
             {/* <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Token Logo</label>
             <br/> */}
             <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Token Logo</label>
             <br/>
             <div>
             <input id="tgt"  type="file"></input>
             </div>
            {/* <input type="file" id="upload" hidden/>
            <label className='myfilehk' for="upload">Choose file</label>    
            <span id="file-chosen">No file chosen</span>            */}
            <p className="small1" style={{color:'#707384'}}>Accepted file type : PNG, JPG, JPEG</p>
        </div>
    </form>
    </div>
    <div className="col-md-12 col-sm-12">
            <div className='form-group'>
            <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Token Description</label>
             <br/>    
            <textarea rows="4" cols="120" className='form-control tokenI' name="comment" form="usrform">Token Description...</textarea>            
            </div>
    </div>
</div>
 
 
                </div>
 
                <center className="mt30" style={{marginTop:'60px'}}>
                  <Link to="/payment">
                <button className="ButtonErctoken">Confirm    
                    <img  src={ercbtn} style={{height:'4vh',marginLeft:'20px'}}/>
                </button>
                </Link>
               </center>
               
            </div>
            </div>
            <Footer/>
 
         
            </>
        )
    }
}