import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form, Row, Col ,Modal, Navbar , Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import Header from './Header/Header2';
import cross from '../Images/cross.png'
import Footer from './Footer';
import { Tune } from '@mui/icons-material';
import axios from 'axios'
import ReactPaginate from 'react-paginate';
export default class DeployedToken extends Component {
  constructor(props, ...args) {
    super(props, ...args);
  this.state = {
        show:false,
        myTokens:[],
        validationFailed:false,
        modalData:'',

        offset: 0,
        data: [],
        perPage: 10,
        currentPage: 0,
    }
  }
    handlePageClick = (e) => {
      const selectedPage = e.selected;
      const offset = selectedPage * this.state.perPage;
  
      this.setState({
          currentPage: selectedPage,
          offset: offset
      }, () => {
          this.componentDidMount()
      });
  };

    handleClose = ()=>{
      this.setState({
        show:false
      })
    }

    handleTokenDetails=(data)=>{
      // console.log(data)
        this.setState({
            show:true,
            modalData:data
          })
    }
    componentDidMount(){
      let token = sessionStorage.getItem("token")
      let email = sessionStorage.getItem("email")
            if(!token || !email){
                window.location.href="/signin"
            }
            else{
              this.setState({
                token:token,
                email:email
              })
              const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
              axios.post("https://deploy.sharkweb.com/api/getUserDeployedToken",{email:email},config)
              .then(res=>{
                if(res.data==='Authorization failed'){
                  sessionStorage.clear();
                  window.location.href='/signin'
                }
                else if(res.data==='User validation failed'){
                  this.setState({
                    validationFailed:true
                  })
                }
                else{
                  const dataOne = res.data.reverse();
            const slice = dataOne.slice(this.state.offset, this.state.offset + this.state.perPage)
            const postData = slice.map(index => {
                  return(
                    <>
                  <React.Fragment>
                          <tbody>
                          <tr className="tableHover" style={{backgroundColor:'#1b1b3e'}} onClick={()=>this.handleTokenDetails(index)}>
                          <td scope=""  className="tableHoverTd" style={{color:'#3d66c5'}}>{index.tokenName}</td>
                          <td>{index.tokenType}</td>
                          <td className="tableHoverTd ellipsis text-truncate" style={{color:'#50a7df'}}>{index.tokenAddress.slice(0,8)+'...'+index.tokenAddress.slice(56,64)}</td>
                          <td>{index.tokenSymbol}</td>
                          </tr>
                          </tbody>
                  </React.Fragment>
                  </>
                    )
                  })
                  this.setState({
                    pageCount: Math.ceil(dataOne.length / this.state.perPage),
                    postData
                  })
                }
              })
            }
    }
    render() {
        return (
            <>

               <div className="Deployed" style={{height:'90vh'}}>
                <Header/>
                <div style={{paddingTop:'3%'}}>
                <h1 className="Dfont" style={{fontWeight:'500',fontSize:'35px'}}>User Deployed Token</h1>

                <div className="table-responsive table-borderless" style={{backgroundColor:'#13132e',padding:'3%',marginTop:'3.5%'}}>
                  {this.state.validationFailed?
                <h3>User Authentication Failed</h3>  
                :
                <>
                    <table className="table text-center">
  <thead>
    <tr>
      <th scope="col">Token Name</th>
      <th scope="col">Token Type</th>
      <th scope="col">Address</th>
      <th scope="col">Token Symbol</th>
    </tr>
  </thead>
  {this.state.postData}
  {/* <tbody>
    <tr className="tableHover" style={{backgroundColor:'#1b1b3e'}} onClick={this.handleTokenDetails}>
      <td scope=""  className="tableHoverTd" style={{color:'#3d66c5'}}>Xyz Token</td>
      <td>Simple AIFI22</td>
      <td className="tableHoverTd ellipsis" style={{color:'#50a7df'}}>0xUZ1b76sa92</td>
      <td>xyzz</td>
    </tr>
    <tr className="tableHover"  style={{backgroundColor:'#202044'}} onClick={this.handleTokenDetails}>
    <td scope=""  className="tableHoverTd" style={{color:'#3d66c5'}}>Xyz Token</td>
      <td>Simple AIFI22</td>
      <td className="tableHoverTd ellipsis" style={{color:'#50a7df'}}>0xUZ1b76sa92</td>
      <td>xyzz</td>
    </tr>
    <tr className="tableHover" style={{backgroundColor:'#1b1b3e'}} onClick={this.handleTokenDetails}>
    <td scope=""  className="tableHoverTd" style={{color:'#3d66c5'}}>Xyz Token</td>
      <td>Simple AIFI22</td>
      <td className="tableHoverTd ellipsis" style={{color:'#50a7df'}}>0xUZ1b76sa92</td>
      <td>xyzz</td>
    </tr>
    <tr className="tableHover"  style={{backgroundColor:'#202044'}} onClick={this.handleTokenDetails}>
    <td scope=""  className="tableHoverTd" style={{color:'#3d66c5'}}>Xyz Token</td>
      <td>Simple AIFI22</td>
      <td className="tableHoverTd ellipsis" style={{color:'#50a7df'}}>0xUZ1b76sa92</td>
      <td>xyzz</td>
    </tr>
    <tr className="tableHover" style={{backgroundColor:'#1b1b3e'}} onClick={this.handleTokenDetails}>
    <td scope=""  className="tableHoverTd" style={{color:'#3d66c5'}}>Xyz Token</td>
      <td>Simple AIFI22</td>
      <td className="tableHoverTd ellipsis" style={{color:'#50a7df'}}>0xUZ1b76sa92</td>
      <td>xyzz</td>
    </tr>
  </tbody> */}
</table>
<ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}/>
{/* <center>
    <u>
    <Link to="" style={{color:'white'}}>See all</Link>
    </u>
</center> */}
                </>
                }
            
                </div>
                </div>
            </div>

               <Footer/>

               <Modal show={this.state.show} onHide={this.handleClose} size='lg'>
       
      
          
        
        <Modal.Body  style={{padding:'6%',fontSize:'15px'}}>
            <Modal.Header style={{padding:'0px',borderBottom:'0px'}}>
            <Modal.Title style={{color:'#0b3281',fontWeight:'600'}}>Databse Relation</Modal.Title>
          {/* <i className="text-right fa fa-times" onClick={this.handleClose} style={{cursor:'pointer'}}></i> */}
          <img src={cross} className="cross" onClick={this.handleClose} style={{position:'absolute',right:'0',top:'0',height:'12.5vh',cursor:'pointer'}}/>
            </Modal.Header>
      
            <hr/>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>ID</p>
                    </div>
                    <div className="col-6">
                        <p style={{fontWeight:'500',color:'#787878'}}>{this.state.modalData?this.state.modalData._id.slice(0,3)+'...'+this.state.modalData._id.slice(3,6):''}</p>
                        </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>Token Name</p>
                    </div>
                    <div className="col-6">
                        <p style={{fontWeight:'500',color:'#787878'}}>{this.state.modalData?this.state.modalData.tokenName:''}</p>
                        </div>
                </div>
                </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>Description</p>
                    </div>
                    <div className="col-6">
                        <p style={{fontWeight:'500',color:'#787878'}}>{this.state.modalData?this.state.modalData.tokenDescription:''}</p>
                        </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>Status</p>
                    </div>
                    <div className="col-6">
                        <p style={{fontWeight:'500',color:'#787878'}}>{this.state.modalData?this.state.modalData.status:''}</p>
                        </div>
                </div>
                </div>
          </div>
          <br/>
          <center>
          <a href="/"><p>More details</p></a>
          </center>

          <Modal.Header style={{padding:'0px',borderBottom:'0px',paddingTop:'5%'}}>
            <Modal.Title style={{color:'#0b3281',fontWeight:'600'}}>Blockchain</Modal.Title>
          
            </Modal.Header>
      
            <hr/>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>Token address</p>
                    </div>
                    <div className="col-6">
                        <p className="ellipsis" style={{fontWeight:'500',color:'#787878'}}>{this.state.modalData?this.state.modalData.tokenAddress.slice(0,5)+'...'+this.state.modalData.tokenAddress.slice(59,64):''}</p>
                        </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>Owner address</p>
                    </div>
                    <div className="col-6">
                        <p className="ellipsis" style={{fontWeight:'500',color:'#787878'}}>{this.state.modalData?this.state.modalData.owner:''}</p>
                        </div>
                </div>
                </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>Transaction ID</p>
                    </div>
                    <div className="col-6">
                        <p className="ellipsis" style={{fontWeight:'500',color:'#787878'}}>{this.state.modalData?this.state.modalData.transactionID:''}</p>
                        </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>Token type</p>
                    </div>
                    <div className="col-6">
                        <p style={{fontWeight:'500',color:'#787878'}}>{this.state.modalData?this.state.modalData.tokenType:''}</p>
                        </div>
                </div>
                </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>Mint</p>
                    </div>
                    <div className="col-6">
                        <p className="ellipsis" style={{fontWeight:'500',color:'#787878'}}>{this.state.modalData?this.state.modalData.mintable:''}</p>
                        </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>Burn</p>
                    </div>
                    <div className="col-6">
                        <p style={{fontWeight:'500',color:'#787878'}}>{this.state.modalData?this.state.modalData.burnable:''}</p>
                        </div>
                </div>
                </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>Pause</p>
                    </div>
                    <div className="col-6">
                        <p className="ellipsis" style={{fontWeight:'500',color:'#787878'}}>{this.state.modalData?this.state.modalData.pausable:''}</p>
                        </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>Ownership</p>
                    </div>
                    <div className="col-6">
                        <p style={{fontWeight:'500',color:'#787878'}}>{this.state.modalData?this.state.modalData.ownershipTransferable:''}</p>
                        </div>
                </div>
                </div>
          </div>
          <br/>
          <center>
          <a href={`/mintable?${this.state.modalData?this.state.modalData._id:''}`}><p>More details</p></a>
          </center>

          {/* <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>Burn</p>
                    </div>
                    <div className="col-6">
                        <p className="ellipsis" style={{fontWeight:'500',color:'#787878'}}>{this.state.modalData?this.state.modalData.burnable:''}</p>
                        </div>
                </div>
            </div>
            
          </div> */}

        </Modal.Body>
    </Modal>
            </>
        )
    }
}