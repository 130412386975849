import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form, Row, Col , Navbar , Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import Header2 from './Header/Header2';
import Footer from './Footer';
import outer from '../Images/outerp.png'
import p2 from '../Images/ProfilePass.png'
import P from '../Images/p2.png'
import ercbtn from '../Images/ercbtn.png'
import ProfileP from '../Images/ProfileP.png'
import axios from 'axios'
import copy from 'copy-to-clipboard';
import gif from '../Images/sharkweb.gif'
import ReCAPTCHA from "react-google-recaptcha"
var CryptoJS = require("crypto-js");


export default class Profile extends Component {
  constructor(props, ...args) {
    super(props, ...args);
  this.state={
        email:'',
        token:'',
        totalTokens:0,
        showPassword:'',
        showCPassword:'',
        showNewPassword:'',
        id:'',
        msgg:'',
        password:'',        
        newPass:'',
        c_new_pass:'',
        msg:'',

        callback: "not fired",
        value: "[empty]",
        load: false,
        expired: "false",
        recaptchaLoaded: false,
        captchaToken:'',

    };
    this._reCaptchaRef = React.createRef();
  }
  captchaChange=(value)=> {
    this.setState({ value });
    if (value === null) this.setState({ expired: "true" });
    this.setState({
      captchaToken:value
    })
  }
    handleChange = (e) =>{
        this.setState({
        [e.target.name]:e.target.value
        })
      }
      handleEye = () =>{
        this.setState({
        showPassword:!this.state.showPassword
        })
      }
      handleNewEye = () =>{
        this.setState({
        showNewPassword:!this.state.showNewPassword
        })
      }
      handleCEye = () =>{
        this.setState({
        showCPassword:!this.state.showCPassword
        })
      }
    componentDidMount(){
        let token = sessionStorage.getItem("token")
        let email = sessionStorage.getItem("email")

        this._reCaptchaRef.current.execute();

              if(!token || !email){
                  window.location.href="/signin"
              }
              else{
                this.setState({
                  token:token,
                  email:email
                })
                const config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
                // axios.get("https://deploy.sharkweb.com/api/validateToken",config).then(res=>{
                axios.get("https://deploy.sharkweb.com/api/validateToken",config).then(res=>{
                  // console.log(res.data,'res')
                  this.setState({
                    id:res.data.data._id
                  })
                })

                  axios.post("https://deploy.sharkweb.com/api/getUserDeployedToken",{email:email},config)
                  .then(res=>{
                    if(res.data==='Authorization failed'){
                      sessionStorage.clear();
                      window.location.href='/signin'
                    }
                    else if(res.data==='User validation failed'){
                      this.setState({
                        validationFailed:true
                      })
                    }
                    else{
                        this.setState({
                            totalTokens:res.data.length
                        })
                    }
                })
                
            }
    }
    copyId = () => {
      copy(this.state.id);
      this.setState({
        msgg: 'Copied!'
      })
      setTimeout(()=>{
        this.setState({
          msgg: ''
        })
      },3000)
    }
    changPassword=()=>{
      this.setState({
        msg:<img src={gif} style={{height:'2rem',width:'2rem'}} alt="loader" />
      })
      if(this.state.oldPassword.length > 0 && this.state.newPassword.length >0 && this.state.CPassword.length >0){
        if(this.state.newPassword===this.state.CPassword){
          let newPass = this.state.newPassword
          let email = this.state.email
          let password = this.state.oldPassword
          let encrypted = CryptoJS.AES.encrypt(email,"key")
          let encrypted1 = CryptoJS.AES.encrypt(password,"key")
         
          axios.post("https://deploy.sharkweb.com/api/AccChangePass",
          {
            encrypt1:encrypted.toString(),encrypt2:encrypted1.toString(),newPass:newPass,aptchaToken:this.state.captchaToken,
          })
          .then(res=>{
            this._reCaptchaRef.current.execute();
                  if(res.data){
                      this.setState({
                          msg:"Successfully changed password , Kindly login again."
                      })
                      setTimeout(()=>{
                          sessionStorage.clear();
                          window.location.reload();
                      },3000)
                  }
          })
          .catch(err=>{
              this.setState({
                  msg:"Wrong old password"
              })
          })
      }else{
          this.setState({
              msg:"Entered password doesn't match"
          })
      }
    }else{
      this.setState({
        msg:'All Fields are Mandatory'
      })
    }
   
    }
    render() {
        return (
            <>
            <div className="profile">
            <Header2/>

            <div className="row mt-5" >
                <div className="col-md-5 col-sm-12" style={{height:''}}>
                {/* <div className="">
            <div className="row">
                                <div className="col-md-3 col-sm-12">
                                    <div className="outer">
                          
                                    </div>
                               
                                </div>
                                <div className="col-md-9 col-sm-12 p-0 p15" style={{marginTop:'7%'}}>
                
                                <p style={{fontSize:'20px',fontWeight:'500'}}>{this.state.email}</p>
                                </div>
                            </div>
            
                <div className="CardP">
                    <div className="row">
                        <div className="col-md-9 col-sm-12">
                         
                                <p style={{fontSize:'18px',fontWeight:'',marginBottom:'5px',color:'#828284'}}>Your Email</p>
                                <p style={{fontSize:'18px',fontWeight:'500'}}>{this.state.email}</p>
                            
                      
                        </div>
                       
                    </div>

                
                    <div className="mt-3"> 
                    <p style={{fontSize:'18px',fontWeight:'',marginBottom:'5px',color:'#828284'}}>Total no. of Token Deployed</p>
                        <p style={{fontSize:'18px',fontWeight:'500'}}>{this.state.totalTokens}</p>
                    </div>
                </div>
            </div> */}
              <div className=" HeightCardP">
                
                    <img src={ProfileP} style={{height:'8.5vh'}}/>
                    <br/>   <br/>
                <p style={{fontSize:'22px',fontWeight:'600'}}>Profile</p>
                {/* <p style={{fontSize:'10px',fontWeight:'',color:'grey'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
labore et dolore magna aliqua.</p> */}
<br/>
{/* <center>
<img src={P} style={{height:'10vh',borderRadius:'50rem',marginBottom:'15px'}}/>
<p style={{fontWeight:'600'}}>John Doe</p>
</center> */}
<br/>
<div className="row">
{/* <div className="col-md-6 col-sm-12">
<p style={{marginBottom:'5px'}}>Your Name</p>
<p style={{color:'gray'}}>John Doe</p>
</div> */}
<div className="col-md-12 col-sm-12">
<p style={{marginBottom:'5px'}}>ID</p>
<p style={{color:'gray'}}>{this.state.id?this.state.id:''} <i onClick={this.copyId} className="fa fa-clipboard" style={{color:'#fff',cursor:'pointer',margin:'0 5px'}} aria-hidden="true"></i>
<p style={{color:'green'}}>{this.state.msgg}</p>
</p> 

</div>
{/* </div> */}
<div className="col-md-6 col-sm-12">
<p style={{marginBottom:'5px'}}>Token Deployed</p>
<p style={{color:'gray'}}>{this.state.totalTokens}</p> 
</div>
</div>
<div className="row mt-3">
<div className="col-md-6 col-sm-12">
<p style={{marginBottom:'5px'}}>Your Email</p>
<p style={{color:'gray',textOverflow: 'ellipsis',overflow:'hidden'}}>{this.state.email}</p>
</div>
{/* <div className="col-md-6 col-sm-12">
<p style={{marginBottom:'5px'}}>Token Address</p>
<p style={{color:'gray',textOverflow: 'ellipsis',overflow:'hidden'}}>0xUZ1b76s0920xUZ1b76s0920xUZ1b76s092</p> 
</div> */}
</div>
                </div>
                </div>
                <div className="col-md-7 col-sm-12 Mtop30">
                <div className="  HeightCardP">
                    <img src={p2} style={{height:'8vh'}}/>
                    <br/>   <br/>

                <p style={{fontSize:'22px',fontWeight:'600'}}>Change Password</p>
                <p style={{fontSize:'10px',fontWeight:'',color:'grey'}}>To change your password, please fill in the fields below. Your password must contain at least 8 characters, it must also include at least
one upper case letter, one lower case letter, one number and also one special character.</p>
<br/>
                <form>
  <div className="form-group">
    <label for="exampleInputEmail1" style={{fontWeight:'600'}}>Current Password</label>
    {/* <input type="password" className="form-control Pform" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter old password"
   /> */}
        <div className="input-group mb-3" style={{border:'1px solid #3d3d4f',borderRadius:'.25rem'}}>
        <div className="input-group-prepend">
    <span className="input-group-text" id="basic-addon1" style={{backgroundColor:'transparent',border:'none',cursor:'pointer'}}>
        <i className="fa fa-lock"></i>
    </span>
  </div>
<input  name="oldPassword" 
type={this.state.showPassword?"text" : "password"} 
onChange={this.handleChange} 
className="form-control Pform" 
placeholder="***********" 
style={{border:'none',color:'#fff'}}
/>

<div className="input-group-append">
<span className="input-group-text" id="basic-addon2" style={{backgroundColor:'transparent',border:'none',cursor:'pointer'}}> 
{
this.state.showPassword 
?
<i className="fa fa-eye" onClick={this.handleEye} ></i>
: 
<i className="fa fa-eye-slash " aria-hidden="true" onClick={this.handleEye} style={{zIndex:"999"}}></i>
}
</span>
</div>
</div>
  </div>
  {/* <div className="form-group">
    <label for="exampleInputEmail1">New Password</label>
    <input type="password" className="form-control Pform" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter new password"/>
  </div>
  <div className="form-group">
    <label for="exampleInputEmail1">Confirm Password</label>
    <input type="password" className="form-control Pform" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Confirm password"/>
  </div> */}

<div className="form-group mt-4">
    <label for="exampleInputEmail1" style={{fontWeight:'600'}}>New Password</label>

        <div className="input-group mb-3" style={{border:'1px solid #3d3d4f',borderRadius:'.25rem'}}>
        <div className="input-group-prepend">
    <span className="input-group-text" id="basic-addon1" style={{backgroundColor:'transparent',border:'none',cursor:'pointer'}}>
        <i className="fa fa-lock"></i>
    </span>
  </div>
<input  name="newPassword" 
type={this.state.showNewPassword?"text" : "password"} 
onChange={this.handleChange} 
className="form-control Pform" 
placeholder="***********" 
style={{border:'none',color:'#fff'}}
/>

<div className="input-group-append">
<span className="input-group-text" id="basic-addon2" style={{backgroundColor:'transparent',border:'none',cursor:'pointer'}}> 
{
this.state.showNewPassword 
?
<i className="fa fa-eye" onClick={this.handleNewEye} ></i>
: 
<i className="fa fa-eye-slash " aria-hidden="true" onClick={this.handleNewEye} style={{zIndex:"999"}}></i>
}
</span>
</div>
</div>
  </div>
  <div className="form-group mt-4">
    <label for="exampleInputEmail1" style={{fontWeight:'600'}}>Confirm Password</label>

        <div className="input-group mb-3" style={{border:'1px solid #3d3d4f',borderRadius:'.25rem'}}>
        <div className="input-group-prepend">
    <span className="input-group-text" id="basic-addon1" style={{backgroundColor:'transparent',border:'none',cursor:'pointer'}}>
        <i className="fa fa-lock"></i>
    </span>
  </div>
<input  name="CPassword" 
type={this.state.showCPassword?"text" : "password"} 
onChange={this.handleChange} 
className="form-control Pform" 
placeholder="***********" 
style={{border:'none',color:'#fff'}}
/>

<div className="input-group-append">
<span className="input-group-text" id="basic-addon2" style={{backgroundColor:'transparent',border:'none',cursor:'pointer'}}> 
{
this.state.showCPassword 
?
<i className="fa fa-eye" onClick={this.handleCEye} ></i>
: 
<i className="fa fa-eye-slash " aria-hidden="true" onClick={this.handleCEye} style={{zIndex:"999"}}></i>
}
</span>
</div>
</div>
  </div>
  </form>
  <center>
  <button onClick={this.changPassword} className="ButtonErc20 " style={{marginTop:'15px',width:'100%',minHeight:'5vh',borderRadius:'.5rem',fontSize:'15px',fontWeight:'600'}}>Change Password    
                    
                </button>
                <center>{this.state.msg}</center>
  </center>

                    </div>     
                    </div>
            </div>
           <br/>
            </div>
            <Footer/>
            <ReCAPTCHA
    sitekey="6LfdhT4pAAAAABS4bfqKSS3vByEJorSz-Aen3Aji"
    onChange={this.captchaChange}
    size="invisible"
    ref={this._reCaptchaRef}
 
  />
          
            </>
        )
    }
}