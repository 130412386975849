import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form, Row, Col , Navbar , Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import signin from '../Images/signin.png'
import Logo from '../Images/logo5.png'
import ercbtn from '../Images/ercbtn.png'
import axios from 'axios'
import ReCAPTCHA from "react-google-recaptcha"
import gif from '../Images/sharkweb.gif'
var CryptoJS = require("crypto-js");
export default class PasswordRecovery extends Component {
    constructor(props, ...args) {
        super(props, ...args);
      this.state={
        callback: "not fired",
        value: "[empty]",
        load: false,
        expired: "false",
        recaptchaLoaded: false,
        captchaToken:'',

        resetPassMsg:'',
        otp:'',
        new_pass:'',
        password:'',
        email:''
      };
      this._reCaptchaRef = React.createRef();
    }
    captchaChange=(value)=> {
        this.setState({ value });
        if (value === null) this.setState({ expired: "true" });
        this.setState({
          captchaToken:value
        })
      }
      componentDidMount(){
          this._reCaptchaRef.current.execute();
      }

      resetPass = () =>{
        this.setState({
            resetPassMsg:<img src={gif} style={{height:'2rem',width:'2rem'}} alt="loader" />
        })
        if(this.state.otp.length>0 && this.state.new_pass.length>0 && this.state.password.length>0){
          if(this.state.password===this.state.new_pass){
            var email = sessionStorage.getItem("reset_email")
            axios.post("https://deploy.sharkweb.com/api/resetpassword",{
                email:email,
                otp:this.state.otp,
                password:this.state.new_pass,
                captchaToken:this.state.captchaToken
            })
            .then(res=>{
              this._reCaptchaRef.current.execute();
                if(res.data==='Invalid captcha'){
                  this.setState({
                    resetPassMsg:res.data
                })
                }
                else if(res.data==='Password changed successfully'){
                    this.setState({
                        resetPassMsg:res.data
                    })
                    sessionStorage.removeItem("reset_email")
                    setTimeout(()=>{
                        window.location.href="/signin"
                    },2000)
                }
            })
          }
          else{
            this.setState({
              resetPassMsg:"Password doesn't match"
            })
          }
        }
        else{
            this.setState({
                resetPassMsg:'Enter details correctly'
            })
        }
    }
    handleChange = (e) =>{
      this.setState({
      [e.target.name]:e.target.value
      })
    }
    render() {
    
        return (
            <>
<div>
    <div className="row w-100 m-0">
        <div className="col-md-3 col-lg-6 col-sm-12 p-0">

            <div className="RecoveryPass">
       
             



  
                        </div>
        </div>
        <div className="col-md-9 col-lg-6  col-sm-12 mobilePadZero p-0">
          <div className="Login" style={{paddingTop:'25%'}}>
            
          
               
                <h1 style={{fontWeight:'700',fontSize:'35px'}}>Password Recovery</h1>
                <p style={{fontSize:'',marginTop:'20px'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
      

            
            <br/>

            <div style={{width:'70%'}}>
            <div className="form-group">
                 <label for="exampleInputEmail1" style={{fontSize:'19px',fontWeight:'600'}}>OTP*</label>
                 <input type="text" className="form-control mt-2 pp" name="otp" onChange={this.handleChange}  id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter confirm password" style={{borderRadius:'1.5rem',minHeight:'6vh',fontSize:'14px'}}/>

                </div>
                <div className="form-group">
                 <label for="exampleInputEmail1" style={{fontSize:'19px',fontWeight:'600'}}>New Password*</label>
                 <input type="password" className="form-control mt-2 pp" name="password" onChange={this.handleChange}  id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter new password" style={{borderRadius:'1.5rem',minHeight:'6vh',fontSize:'14px'}}/>

                </div>
                <div className="form-group">
                 <label for="exampleInputEmail1" style={{fontSize:'19px',fontWeight:'600'}}>Confirm Password*</label>
                 <input type="password" className="form-control mt-2 pp" name="new_pass" onChange={this.handleChange}  id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter confirm password" style={{borderRadius:'1.5rem',minHeight:'6vh',fontSize:'14px'}}/>

                </div>

               
            {/* <Link to="/password-recovery"> */}
            <button onClick={this.resetPass} className="LoginButton mt-2" style={{borderRadius:'1.5rem',minHeight:'6vh'}}>Reset Password</button>
            <center className='mt-2'>
              <p>{this.state.resetPassMsg}</p>
            </center>
            {/* </Link> */}
             
            </div>

           
         



          </div>
        </div>
    </div>
</div>
<ReCAPTCHA
    sitekey="6LfdhT4pAAAAABS4bfqKSS3vByEJorSz-Aen3Aji"
    onChange={this.captchaChange}
    size="invisible"
    ref={this._reCaptchaRef}
 
  />
            </>
        )
    }
}