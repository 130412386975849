import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form, Row, Col , Navbar , Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import signin from '../Images/signin.png'
import Logo from '../Images/logo5.png'
import ercbtn from '../Images/ercbtn.png'
import validator from 'validator'
import axios from 'axios'
import gif from '../Images/sharkweb.gif'
import ReCAPTCHA from "react-google-recaptcha"
var CryptoJS = require("crypto-js");

export default class Signup extends Component {
  constructor(props, ...args) {
    super(props, ...args);
  this.state = {
        showPassword:'',
        email:'',
        password:'',
        cpass:'',
        msg:'',
        checked:'',

        callback: "not fired",
        value: "[empty]",
        load: false,
        expired: "false",
        recaptchaLoaded: false,
        captchaToken:'',
    };
    this._reCaptchaRef = React.createRef();
  }
  captchaChange=(value)=> {
    this.setState({ value });
    if (value === null) this.setState({ expired: "true" });
    this.setState({
      captchaToken:value
    })
  }
    handleChange = (e) =>{
        this.setState({
        [e.target.name]:e.target.value
        })
      }
      handleEye = () =>{
        this.setState({
        showPassword:!this.state.showPassword
        })
      }
      handleCreate=()=>{
          this.setState({
              msg: <img src={gif} style={{height:'2rem',width:'2rem'}} alt="loader" />
            //   <div style={{color:"#fff"}} className="spinner-grow" role="status">
            //   <span className="sr-only">Loading...</span>
            // </div>
            })
          if(this.state.email.length>0 && this.state.password.length>0 && this.state.cpass.length>0){
              if (validator.isStrongPassword(this.state.password, {
                minLength: 8, minLowercase: 1,
                minUppercase: 1, minNumbers: 1, minSymbols: 1
            })) {
              if(this.state.password.length<=100){
                if(this.state.password===this.state.cpass){
                var encryptedemail = CryptoJS.AES.encrypt(this.state.email,"key")
                var encryptedpass = CryptoJS.AES.encrypt(this.state.password,"key")
                axios.post("https://deploy.sharkweb.com/api/signup",{
                  encryptedemail:encryptedemail.toString(),
                  encryptedpass:encryptedpass.toString(),
                  captchaToken:this.state.captchaToken,
                //   encryptedphone:encryptedphone.toString(),
                //   name:this.state.name
                })
                .then(res=>{
                  this._reCaptchaRef.current.execute();
                  this.setState({
                    msg:res.data
                  })
                    // if(res.data!='Please enter a valid email address' || res.data!='User Already Exists'){
                    //     this.setState({
                    //         msg:res.data
                    //     })
                    //     setTimeout(()=>{
                    //         window.location.href="/signin"
                    //     },2000)
                    // }
                    // else{
                    //     this.setState({
                    //         msg:res.data
                    //     })
                    // }
                })
              }
              else{
                this.setState({
                  msg:"Password doesn't match"
                })
              }
            }
            else{
              this.setState({
                msg:"Maximum password length is 100"
              })
            }
            }
            else {
              this.setState({
                msg:"Password must have combination of alphanumeric and special character and should be minimum 8 characters"
              })
          }
          }
          else{
              this.setState({
                  msg:'Please enter all fields correctly!'
              })
          }
      }
      componentDidMount(){
       
          this._reCaptchaRef.current.execute();
      }
      handleCheckboxChange = (event) => {
        this.setState(prevState => ({
         checked: !prevState.checked
       }));
     };
    render() {
        // console.log(this.state)
        return (
            <>
<div>
    <div className="row w-100 m-0">
        <div className="col-md-3 col-lg-6  col-sm-12 p-0">
        <div className="signin">
        <Link to='/'>
            <img src={Logo} style={{height:'6vh',cursor:"pointer"}}/></Link>
                <br/>
                <br/>
                <center>                        
                  <h1 className="sectionHeading">No-Code Token Generator</h1>
                  <br/>
                  <p className="" style={{fontSize:'21px'}}>Create a Token in less than a minute with No-Code Token Generator. 
                  <br/>No coding required.</p>
                  <br/>
                    <div style={{paddingTop:''}}>
                        {/* <Link to="/signin">
                    <button className="ButtonErc20">
                        Create a Token 
                        <img  src={ercbtn} style={{height:'4vh',marginLeft:'20px'}}/>
                    </button>
                    </Link> */}
                    {/* 
                    <button className="ButtonPricing">Pricing</button> */}
                      </div>
                  </center>
                </div>
              </div>
            <div className="col-md-9 col-lg-6 col-sm-12 mobilePadZero p-0">
              <div className="signup">
                <center>
                    <h1 style={{fontWeight:'700'}}>Sign up</h1>
                    <p style={{fontSize:'16px',marginTop:'15px'}}>Welcome to the Web 3.0 era, where no-code development empowers you to seamlessly deploy your Tokens, dApps, and Layer 2 solutions.</p>
                </center>
                <br/>
                <form>
                    <div className="form-group">
                    <label for="exampleInputEmail1" style={{fontSize:'18px',fontWeight:'600'}}>Email</label>
                    <input type="email" className="form-control Pform" name="email" onChange={this.handleChange}  id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"/>
                    </div>
                    
                    <div className="form-group">
                    <label for="exampleInputEmail1 " style={{fontSize:'19px',fontWeight:'600'}}>Password</label>
                      <div className="input-group">

                        <input  name="password" 
                        type={this.state.showPassword?"text" : "password"} 
                        onChange={this.handleChange} 
                        className="form-control Pform" 
                        placeholder="***********" 
                        style={{borderRight:'none'}}
                        />

                        <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2" style={{backgroundColor:'transparent',borderLeft:'none',cursor:'pointer'}}> 
                        {
                        this.state.showPassword 
                        ?
                        <i className="fa fa-eye" onClick={this.handleEye} ></i>
                        : 
                      <i className="fa fa-eye-slash " aria-hidden="true" onClick={this.handleEye} style={{zIndex:"999"}}></i>
                        }
                        </span>
                        </div>
                    </div>
                    <small>E.g - aBcdE@12345</small>
                    </div>

                    <div className="form-group">
                    <label for="exampleInputEmail1" style={{fontSize:'19px',fontWeight:'600'}}>Confirm Password</label>
                    <input type="password" className="form-control Pform" name="cpass" onChange={this.handleChange}  id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="**********"/>

                    </div>
                    <div style={{display:'flex',justifyContent:'start',alignItems:'center'}}>
                  <label style={{color:'black'}}>
              <input
              style={{margin:" 0 5px"}}
                type="checkbox"
                checked={this.state.checked}
                onChange={this.handleCheckboxChange}
                disabled={this.state.disable}
              />
              By signing up, you confirm you have read and agree to SharkWeb <Link> Terms  of Use</Link> and <Link>Privacy Policy</Link>
            </label>
                  </div>
                </form>

                <br/>
                { this.state.checked ?
                <button onClick={this.handleCreate} className="LoginButton">Sign up</button>
                :
                <button disabled style={{backgroundColor:'gray'}} className="LoginButton">Sign up</button>
        }
                <p className='text-center mt-2'>{this.state.msg}</p>
                {/* <br/> */}
                <center>
                    <p>Already have an account? 
                        <Link to="/signin" style={{color:'#3F1585',marginLeft:'5px'}}>
                            <u>Login</u>
                        </Link>
                    </p>
                </center>
              </div>
            </div>
         </div>
      </div>
            
      <ReCAPTCHA
    sitekey="6LfdhT4pAAAAABS4bfqKSS3vByEJorSz-Aen3Aji"
    onChange={this.captchaChange}
    size="invisible"
    ref={this._reCaptchaRef}
 
  /> 
            </>
        )
    }
}