import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table,Modal, Form, Row, Col , Navbar , Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import Header2 from './Header/Header2';
import Footer from './Footer';
import ercbtn from '../Images/ercbtn.png'
import axios from 'axios';
import close from '../Images/close.png';
import gif from '../Images/sharkweb.gif'
import ReCAPTCHA from "react-google-recaptcha"

export default class PaymentApi extends Component {
  constructor(props, ...args) {
    super(props, ...args);
  this.state={
        token:'',
        tokenDetails:null,
        date:new Date().toLocaleDateString(),

        privateKey:'',
        password:'',

        msg:'',
        disabled:false,
        isModalOpen: true,
        lines: [],
        showModal2:false,
        currentWordIndex: 0,
        words: [],

        argForPage:true,

        callback: "not fired",
        value: "[empty]",
        load: false,
        expired: "false",
        recaptchaLoaded: false,
        captchaToken:'',
    };
    this._reCaptchaRef = React.createRef();
  }
  captchaChange=(value)=> {
    this.setState({ value });
    if (value === null) this.setState({ expired: "true" });
    this.setState({
      captchaToken:value
    })
  }
    handleChange = (e) =>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    createAPI = async() =>{
      this.setState({
          msg:<img src={gif} style={{height:'2rem',width:'2rem'}} alt="loader" />,
          disabled:true
      })
      if(this.state.password.length>0 && this.state.privateKey.length>0){
        const config = {
            headers: { Authorization: `Bearer ${this.state.token}` }
        };
        if(this.state.key_type.length>0 && this.state.label.length>0 && this.state.purpose.length>0){
          const config = {
              headers: { Authorization: `Bearer ${this.state.token}` }
          };
         await axios.post("https://deploy.sharkweb.com/api/generateAPIKey",{
              email:this.state.email,
              label: this.state.label,
              purpose: this.state.purpose,
              key_type: this.state.key_type,
              password:this.state.password,
              privateKey:this.state.privateKey,
              captchaToken:this.state.captchaToken,
          },config)
          .then(res=>{
            // console.log(res,'res')
            this._reCaptchaRef.current.execute();
            if(res.data.length  === 36){
              this.setState({
                msg:res.data,
                disabled:false
              })
              setTimeout(()=>{
                window.location.href='/api-list'
              },2000)
            }else{
              this.setState({
                msg:res.data,
                disabled:false
              })
            }                                                   
          })
          .catch(error=>{
            if(error){
              this.setState({
                  msg:'Try again'
              })
            }          
          })
      }
      else{
          this.setState({
              msg:'Details provided by you is not sufficient',
              disabled:false
          })
      }
      }
      else{
        this.setState({
          msg:'Please enter all details',
          disabled:false
      })
      }
     
  }
  componentDidMount=async()=>{
    let token = sessionStorage.getItem("token")
    let email = sessionStorage.getItem("email")
    this._reCaptchaRef.current.execute();
    if(!token || !email){
        window.location.href="/signin"
    }
    else{
      this.setState({
        token:token,
        email:email
      })
      const detailsFrmURL = window.location.href.split('&&')
      // console.log(detailsFrmURL)
      let key_type = detailsFrmURL[1]
      let purpose = detailsFrmURL[2]
      let label = detailsFrmURL[3]
      if(key_type.length>0 && purpose.length>0 && label.length>0){
        this.setState({
          key_type:key_type,
          purpose:purpose,
          label:label
        })
        // console.log("here",label.length)
        const config = {
          headers: { Authorization: `Bearer ${token}` }
      };
       await axios.get("https://deploy.sharkweb.com/api/validateToken",config)
        .then(res=>{
          if(res.data==='User validation failed'){
            sessionStorage.clear();
            window.location.href='/signin'
          }
        })
        .catch(error=>{
          if(error){
            this.setState({
              
            })
          }          
        })
      }
      else{
        this.setState({
          argForPage:false
        })
      }
    }
  }
   
    handleShowModal2 = () => {

        // const jsonData = {
        //   "note": "Transaction created and broadcast successfully.",
        //   "TxnData": {
        //     "amount": 40,
        //     "sender": "swb09762f0052e611ee80c645b889236cc4wza0",
        //     "remark": "AIFI",
        //     "recipient": "swb09762f0052e611ee80c645b889236cc4wqa0",
        //     "transactionId": "e2551d307ec511eea7d44b2d08b82c39",
        //     "timestamp": 1699509886595,
        //   },
        // };
        // const wordsArray = JSON.stringify(jsonData, null, 2).split(/\s+/).filter(Boolean);
        // this.setState({
        //   words: wordsArray,
        //   currentWordIndex: 0,
        //   showModal2: true,
        // });
    
        // this.wordInterval = setInterval(this.animateWords, 300); 
      };
    
     


  componentWillUnmount() {
    // Clear the interval when the component is unmounted
    clearInterval(this.wordInterval);
  }
 
  animateWords = () => {
    const { currentWordIndex, words } = this.state;

    // Stop animation when all words are displayed
    if (currentWordIndex >= words.length) {
      clearInterval(this.wordInterval);
      return;
    }

    // Increment the index for the next word
    this.setState({
      currentWordIndex: currentWordIndex + 1,
    });
  };
    render() {
        const { isModalOpen, lines } = this.state;
        const { currentWordIndex, words } = this.state;
        // console.log(this.state.tokenDetails)
        return (
            <>
            <div className="paymentApi">
            <Header2/>
            <br/>
            <div className="Pycenter">
              {this.state.argForPage?
              <>
               {this.state.tokenDetails==='Payment Expired'?
               <div className="CardP">
                <p className='text-center' style={{fontWeight:"700"}}>{this.state.tokenDetails}</p>   
                </div>
                :
                <>
                 <div className="CardP">

<div className="row pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>
<div className="col-6 p-0">
<span style={{color:'#3F1585',fontWeight:'700',fontSize:'22px'}}>Import Wallet</span>
</div>
<div className="col-6 p-0" style={{textAlign:'right'}}>
<span style={{color:'white',fontSize:'22px',fontWeight:'600'}}>Issuer : </span>
<span  style={{color:'#3F1585',fontSize:'22px',fontWeight:'600'}}> AIFI</span>
    </div>
</div>


<div className="row mt-5 ">
    <div className="col-md-6 col-sm-12">
    <form>
        <div className="form-group">
             <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Private Key</label>
             <input type="password" name="privateKey" onChange={this.handleChange} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your private key"/>

        </div>
    </form>
    </div>
    <div className="col-md-6 col-sm-12">
    <form>
    <div className="form-group">
     <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>Pass Phrase</label>
    <input type="password" name="password" onChange={this.handleChange} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your pass phrase"/>
    </div>
    </form>
    </div>
</div>

<div className="row mt-3 ">
    <div className="col-md-6 col-sm-12">
    <form>
        <div className="form-group">
             <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Date</label>
             <div>


                 <Form.Group controlId="dob" style={{width:''}}>
                 <Form.Control type="" name="dob" placeholder={this.state.date} style={{width:'100%',cursor:''}} disabled/>
                </Form.Group>


             </div>

        </div>
    </form>
    </div>
    <div className="col-md-6 col-sm-12">
    <form>
    <div className="form-group">
     <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>Amount</label>
    <input type="" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="60 AIFI" disabled/>
    </div>
    </form>
    </div>
</div>
<br/>
<p style={{fontSize:'18px',marginBottom:'0px'}}>It may take a few moments for this transaction to appear in your account</p>
</div>

<center style={{marginTop:'40px'}}>
    {this.state.disabled?
        <button className="ButtonErc20"  style={{backgroundColor:"grey",cursor:"unset"}} disabled>Finish and Pay    
<img src={ercbtn} style={{height:'4vh',marginLeft:'20px'}}/>
</button>
:
<button className="ButtonErc20" onClick={this.createAPI}>Finish and Pay    
<img  src={ercbtn} style={{height:'4vh',marginLeft:'20px'}}/>
</button>
}
{this.state.msg === "Try again" ? 
<p style={{color:'red'}}>{this.state.msg}</p>
:
  <p>{this.state.msg}</p>
}
{/* <br/> */}

<p style={{fontSize:'18px',fontWeight:'500',marginBottom:'0px',marginTop:'15px'}}>Need a new wallet?  <Link to="" style={{color:'#3F1585'}}><u>Create it here</u></Link></p>
</center>
                </>
            }
            </>
               :
                <h3 className='text-center'>Invalid argument</h3>
               }
            </div>
            </div>
            <Footer/>
    
 
            <Modal show={this.state.showModal2} size="lg" onHide={() => this.setState({ showModal2: false })}>
          <Modal.Body style={{ backgroundColor: '#0c0c1f', color: '#d2d2d3', padding: '8%' }}>
            <img src={close} style={{ position: 'absolute', right: 0, top: 0, fontSize: '20px', cursor: 'pointer', height: '5vh' }} onClick={() => this.setState({ showModal2: false })} />
            <div className="transaction-info anim">
              <pre style={{padding:'3%'}}>
                 {/* {words.slice(0, currentWordIndex).map((word, index) => (
                  <span key={index} className={index > 1 || word === ':' || word === '{' ? 'blue-text' : 'red-text'}>
                    {word}&nbsp;
                  </span>
                ))}  */}
          

        
          <p className="anim" ><span style={{color:'#FF2E2E'}}>"note"</span> : <span style={{color:' #60A3D9'}}>"Transaction created and broadcast successfully."</span> ,</p>
        <p className="anim"><span style={{color:'#FF2E2E'}}>"TxnData"</span> : <span style={{color:' #60A3D9'}}></span>,</p>
        <p className="anim"><span style={{color:'#FF2E2E'}}>"amount"</span> : <span style={{color:'#60A3D9'}}>40</span></p>
        <p className="anim"><span style={{color:'#FF2E2E'}}>"sender"</span> : <span style={{color:' #60A3D9'}}>"swb09762f0052e611ee80c645b889236cc4wza0"</span>,</p>
        <p className="anim"><span style={{color:'#FF2E2E'}}>"remark"</span> : <span style={{color:' #60A3D9'}}> "AIFI"</span></p>
        <p className="anim"><span style={{color:'#FF2E2E'}}>"recipient"</span> : <span style={{color:' #60A3D9'}}>"swb09762f0052e611ee80c645b889236cc4wqa0"</span>,</p>
        <p className="anim"><span style={{color:'#FF2E2E'}}>"transactionId"</span> :<span style={{color:' #60A3D9'}}> "e2551d307ec511eea7d44b2d08b82c39"</span>,</p>
        <p className="anim"><span style={{color:'#FF2E2E'}}>"timestamp"</span> : <span style={{color:' #60A3D9'}}>1699509886595</span>,</p>

              </pre>
              <center>
                <button onClick={() => this.setState({ showModal2: false })}  style={{backgroundColor:'#3F1585',color:'white',minWidth:'8rem',minHeight:'5vh',border:'none',borderRadius:'.25rem',cursor:'pointer'}}>Close</button>
              </center>
            </div>
          </Modal.Body>
        </Modal>
        <ReCAPTCHA
    sitekey="6LfdhT4pAAAAABS4bfqKSS3vByEJorSz-Aen3Aji"
    onChange={this.captchaChange}
    size="invisible"
    ref={this._reCaptchaRef}
 
  />
            </>
        )
    }
}