import React, { Component } from 'react';
import '../../css/style.css';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import profile1 from '../../Images/p.png';
import Logo from '../../Images/Logo.png';

export default class Header2 extends Component {
  render() {
    return (
      <>
        <Navbar bg="transparent" expand="lg" className="p-0">
          <>
            <Navbar.Brand href="/" style={{ fontSize: '22px', color: 'white' }}>
              <img src={Logo} style={{ height: '7vh' }} alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" style={{backgroundColor:'white'}}/>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
              <Link to="/signin" style={{marginTop:'10px'}}>
            <button className="btn HeaderBtn1 my-2 my-sm-0 p-0" type="submit" >Login</button>
            </Link>
            <Link to="/signup">
            <button className="btn HeaderBtn2 my-2 my-sm-0" type="submit">Register</button>
            </Link>
              </Nav>
            </Navbar.Collapse>
          </>
        </Navbar>
      </>
    );
  }
}
