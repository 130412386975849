import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form, Row, Col , Navbar , Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import Header2 from './Header/Header2';
import Footer from './Footer';
import ercbtn from '../Images/ercbtn.png'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
export default class CreateNft extends Component {

    render() {
        return (
            <>
            <div className="profile">
            <Header2/>
            <br/>
            <h1 className="token">Create your NFT</h1>
            <p style={{fontSize:'20px'}}>Easily deploy Smart Contract for a Standard, Capped, Mintable, Burnable, Payable XYZ Token. No coding required.</p>
           
            <div className="cardWhite" style={{borderRadius:'.25rem',paddingLeft:'3%',paddingRight:'3%',paddingTop:'1%',paddingBottom:'1%',fontSize:'18px',color:'black'}}>
            <i className="fa fa-exclamation-triangle" style={{color:'red',fontSize:'20px',marginRight:'10px'}}></i>
            <span style={{color:'red',fontWeight:'600',marginRight:'5px'}}>Alert</span>
            <span>: To use this app please install <Link to="" style={{fontWeight:'500',color:'#3F1585'}}>AIFI WALLET</Link>. Use any other wallet at your own risk.</span>
            </div>
            <div className="Pycenter">
               
                <div className="CardP">

                    <div className=" pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>
                  
                    <span style={{color:'#3F1585',fontWeight:'700',fontSize:'32px'}}>NFT Details</span>
                   
                    </div>
                   

                    <div className="row mt-5 ">
                        <div className="col-md-6 col-sm-12">
                        <form>
                            <div className="form-group">
                                 <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>NFT Name</label>
                                 <input type="" className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Enter your nft name"/>
                                    <p className="small" style={{color:'#707384'}}>Choose a name for NFT</p>
                            </div>
                        </form>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <form>
                        <div className="form-group">
                         <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>NFT Symbol</label>
                         <input type="" className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Enter your nft symbol"/>
                         <p className="small"  style={{color:'#707384'}}>Choose a symbol for your token </p>
                         </div>
                        </form>
                        </div>
                    </div>

                 
                    
                </div>


                <div className="CardP">

<div className=" pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>

<span style={{color:'#3F1585',fontWeight:'700',fontSize:'32px'}}>NFT Features</span>

</div>


<div className="row mt-5 ">
    <div className="col-md-6 col-sm-12">
    <form>
        <div className="form-group">
             <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>NFT Type</label>
             <br/>
             {/* <Form.Select aria-label="Default select example" style={{width:'100%',backgroundColor:'white',border:'none',minHeight:'6vh',padding:'2.5%',borderRadius:'.25rem'}}>
            <option >Open this select menu</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
             </Form.Select> */}
                  <FormControl  variant="" style={{width:'100%'}}>
        <InputLabel id="select-label" style={{color:'black'}}>NFT Features</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          className="drop"   
         
        >
         
          <MenuItem value="option1">Simple AIFI22</MenuItem>
          {/* <MenuItem value="option2"></MenuItem> */}
       
        </Select>
      </FormControl>
                <p className="small1" style={{color:'#707384'}}>Choose your nft type</p>
        </div>
    </form>
    </div>
    <div className="col-md-6 col-sm-12">
    <form>
    <div className="form-group">
     <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>Network</label>
     <FormControl variant="" style={{width:'100%'}}>
     
        <Select
          labelId="select-label"
          id="select"
          className="drop"   
         
        >
         
          <MenuItem value="option1">Mainnet AIFI22</MenuItem>
          {/* <MenuItem value="option2"></MenuItem> */}
        
        </Select>
      </FormControl>
     <p className="small1" style={{color:'#707384'}}>Choose your network</p>
     </div>
    </form>
    </div>
</div>


<div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{width:'18px',height:'18px',marginRight:'',cursor:'pointer'}}/>
  <label className="form-check-label" for="flexCheckDefault" style={{marginLeft:'10px'}}>
  I have read, understood and agreed to Token Generator’s <Link to="" style={{color:'#3F1585'}}>Terms of Use</Link>
  </label>
</div>


                </div>


            


                <div className="CardP">

<div className=" pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>

<span style={{color:'#3F1585',fontWeight:'700',fontSize:'32px'}}>Transactions</span>

</div>


<div className="row mt-5 ">
    <div className="col-md-6 col-sm-12">
             <p style={{fontSize:'20px',color:'#b8b8b8'}}>Commission Fee : <i className="fa fa-exclamation-circle" style={{color:'white'}}></i></p>
    </div>
    <div className="col-md-6 col-sm-12" style={{float:'right',textAlign:'right'}}>
     <button style={{backgroundColor:"#3F1585",border:"none",borderRadius:'.25rem',color:'white',fontWeight:'500',minWidth:'7rem',minHeight:'5vh',cursor:'pointer'}}>40 AIFI</button>
    </div>
</div>

<div className="row mt-3 ">
    <div className="col-md-6 col-sm-12">
             <p style={{fontSize:'20px',color:'#b8b8b8'}}>Gas Fee : <i className="fa fa-exclamation-circle" style={{color:'white'}}></i></p>
    </div>
    <div className="col-md-6 col-sm-12" style={{float:'right',textAlign:'right'}}>
     <button style={{backgroundColor:"#29bd6d",border:"none",borderRadius:'.25rem',color:'white',fontWeight:'500',minWidth:'7rem',minHeight:'5vh',cursor:'pointer'}}>Variable</button>
    </div>
</div>





                </div>




                <center className="mt30" style={{marginTop:'60px'}}>
                  <Link to="/payment">
                <button className="ButtonErc20">Confirm    
                    <img  src={ercbtn} style={{height:'4vh',marginLeft:'20px'}}/>
                </button>
                </Link>
               </center>
               
            </div>
            </div>
            <Footer/>
 
          
            </>
        )
    }
}