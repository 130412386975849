import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form, Row, Col , Navbar , Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import Header2 from './Header/Header2';
import Footer from './Footer';
import ercbtn from '../Images/ercbtn.png'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import axios from 'axios'
import gif from '../Images/sharkweb.gif'

import ReCAPTCHA from "react-google-recaptcha"

var CryptoJS = require("crypto-js");

export default class CreateToken extends Component {
    constructor(props, ...args) {
        super(props, ...args);
      this.state={
         callback: "not fired",
          value: "[empty]",
          load: false,
          expired: "false",
          recaptchaLoaded: false,
          captchaToken:'',
      };
        this._reCaptchaRef = React.createRef();
    }
     captchaChange=(value)=> {
        this.setState({ value });
        if (value === null) this.setState({ expired: "true" });
        this.setState({
          captchaToken:value
        })
      }

handleChange = (e) =>{
  this.setState({
      [e.target.name]:e.target.value
  })

}
componentDidMount(){
    this._reCaptchaRef.current.execute();
}
    render() {
     
        return (
            <>
            <div className="profile">
            <Header2/>
            <br/>
            <h1 className="token">Token Description</h1>
            <p style={{fontSize:'20px'}}>Easily deploy Smart SharkCode Contract for a Standard, Pausable, Mintable, Burnable, Ownership Transferable. No coding required.</p>
           
            <div className="cardWhite" style={{borderRadius:'.25rem',paddingLeft:'3%',paddingRight:'3%',paddingTop:'1%',paddingBottom:'1%',fontSize:'18px',color:'black'}}>
            <i className="fa fa-exclamation-triangle" style={{color:'red',fontSize:'20px',marginRight:'10px'}}></i>
            {/* <span style={{color:'red',fontWeight:'600',marginRight:'5px'}}>Alert</span> */}
            <span> You must have a wallet created before creating token using this platform. Please visit <Link to={'https://faucet.sharkwallet.app/'} target='_blank'> https://faucet.sharkwallet.app/ </Link> for wallet creation.
</span>
            </div>
            <div className="Pycenter mt-5">
               
                <div className="CardP">

                    <div className=" pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>
                  
                    <span style={{color:'#7739de',fontWeight:'700',fontSize:'32px'}}>Token Details</span>
                   
                    </div>
                   

                    <div className=" mt-5 ">
                        <div className="col-md-12 col-sm-12">
                        <form>
                            <div className="form-group">
                                 <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Token Description</label>
                                 <textarea type="text" name="tokenDescription" onChange={this.handleChange} className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Enter your token description"
                                 style={{minHeight:'20vh'}}></textarea>
                                    {/* <p className="small"></p> */}
                            </div>
                        </form>
                        </div>
                        <div className="col-md-12 col-sm-12">
                        <form>
                        <div className="form-group">
                         <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>Token Logo String</label>
                         <input type="text" name="tokenLogoString" onChange={this.handleChange} className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Enter token logo string"/>
                       
                         </div>
                        </form>
                        </div>
                    </div>

                   
                    
                </div>
</div>


<div className="Pycenter  mt-5">
              



                <center className="mt30" style={{marginTop:'60px'}}>
                  <Link to="/description-payment">

             <button className="ButtonErc20" onClick={this.submit}>Confirm    
                    <img  src={ercbtn} style={{height:'4vh',marginLeft:'20px'}}/>
                </button>
             

                {/* <p className='text-center' style={{color:this.state.msg==='Try again'?'red':'',fontSize:'14px'}}>{this.state.msg}</p> */}
             
                </Link>
               </center>
               
            </div>
            </div>
            <Footer/>
 
          <ReCAPTCHA
    sitekey="6LfdhT4pAAAAABS4bfqKSS3vByEJorSz-Aen3Aji"
    onChange={this.captchaChange}
    size="invisible"
    ref={this._reCaptchaRef}
 
  />
            </>
        )
    }
}