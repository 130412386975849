import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form,Modal, Row, Col , Navbar , Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import Header2 from './Header/Header2';
import Footer from './Footer';
import ercbtn from '../Images/ercbtn.png'
import axios from 'axios';
import close from '../Images/close.png';
import gif from '../Images/sharkweb.gif'
import ReCAPTCHA from "react-google-recaptcha"
export default class Payment extends Component {

  constructor(props, ...args) {
    super(props, ...args);
  this.state={
        token:'',
        tokenDetails:null,
        date:new Date().toLocaleDateString('en-US'),

        privateKey:'',
        password:'',

        msg:'',
        disabled:false,
        showModal2:false,
        modalMsg:'',

     callback: "not fired",
     value: "[empty]",
     load: false,
     expired: "false",
     recaptchaLoaded: false,
     captchaToken:'',
    };
    this._reCaptchaRef = React.createRef();
  }
    handleChange = (e) =>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    captchaChange=(value)=> {
      this.setState({ value });
      if (value === null) this.setState({ expired: "true" });
      this.setState({
        captchaToken:value
      })
    }
    componentWillUnmount() {
    // Clear the interval when the component is unmounted
    clearInterval(this.wordInterval);
  }
 
  animateWords = () => {
    const { currentWordIndex, words } = this.state;

    // Stop animation when all words are displayed
    if (currentWordIndex >= words.length) {
      clearInterval(this.wordInterval);
      return;
    }

    // Increment the index for the next word
    this.setState({
      currentWordIndex: currentWordIndex + 1,
    });
  };
    componentDidMount(){
      this._reCaptchaRef.current.execute();
        const jsonData = {        
      "note": "Transaction created and broadcast successfully.",     
      "TxnData": {
        "amount": 40,
        "sender": "swb09762f0052e611ee80c645b889236cc4wza0",
        "remark": "AIFI",
        "recipient": "swb09762f0052e611ee80c645b889236cc4wqa0",
        "transactionId": "e2551d307ec511eea7d44b2d08b82c39",
        "timestamp": 1699509886595
    }
    
    };
    const wordsArray = JSON.stringify(jsonData, null, 2).split(/\s+/).filter(Boolean);
    this.setState({ words: wordsArray });
    this.wordInterval = setInterval(this.animateWords, 300); // Adjust the interval as needed
 
        let token = sessionStorage.getItem("token")
        let email = sessionStorage.getItem("email")
        if(!token || !email){
            window.location.href="/signin"
        }
        else{
          this.setState({
            token:token,
            email:email
          })

          const feesDetailsToken = window.location.href.split('?')[1]
          const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
          axios.post("https://deploy.sharkweb.com/api/verifyCodeForFees",{
            feesDetailsToken:feesDetailsToken,
            email:email
          },config)
          .then(res=>{
              if(res.data==='Payment Expired'){
                this.setState({
                    tokenDetails:"Payment Expired"
                  })
              }
              else if(res.data==='Authorization failed'){
                sessionStorage.clear();
                window.location.href='/signin'
              }
              else{
                this.setState({
                    tokenDetails:res.data
                  })          
              }
          })
        } 
    }

    handleSubmit = () =>{
        this.setState({
            msg: <img src={gif} style={{height:'2rem',width:'2rem'}} alt="loader" />,
            disabled:true
        })
        // var email=localStorage.getItem("tcytg")
        if(this.state.password.length>0 && this.state.privateKey.length>0){
        const config = {
            headers: { Authorization: `Bearer ${this.state.token}` }
        };
        axios.post("https://deploy.sharkweb.com/api/createTokenReq",{
          tokenName:this.state.tokenDetails.decodedTokenForFees.tokenData.tokenName,
          tokenSymbol:this.state.tokenDetails.decodedTokenForFees.tokenData.tokenSymbol,
          decimal:this.state.tokenDetails.decodedTokenForFees.tokenData.decimal,
          totalSupply:this.state.tokenDetails.decodedTokenForFees.tokenData.totalSupply,
          mintable:this.state.tokenDetails.decodedTokenForFees.tokenData.mintable,
          ownershipTransferable:this.state.tokenDetails.decodedTokenForFees.tokenData.ownershipTransferable,
          pausable:this.state.tokenDetails.decodedTokenForFees.tokenData.pausable,
          burnable:this.state.tokenDetails.decodedTokenForFees.tokenData.burnable,
          tokenType:this.state.tokenDetails.decodedTokenForFees.tokenData.tokenType,
          network:this.state.tokenDetails.decodedTokenForFees.tokenData.network,
          fees:this.state.tokenDetails.decodedTokenForFees.fees,
          privateKey:this.state.privateKey,
          password:this.state.password,
          email:this.state.email,
          captchaToken:this.state.captchaToken,
        },config)
        .then(res=>{
          this._reCaptchaRef.current.execute();
          // console.log(res.data)
          if(res.data==='Token already present with the same name.'){
            this.setState({
              msg:'Token already present with the same name.',
              disabled:false
            })
          }
          else if(res.data==='Insufficient fund'){
            this.setState({
              msg:'Insufficient fund',
              disabled:false
            })
          }
          else if(res.data==='User not found'){
            this.setState({
              msg:'User not found'
            })
          }
          else if(res.data==='Wrong privatekey or passphrase'){
            this.setState({
              msg:'Wrong privatekey or passphrase'
            })
          }
          else if(res.data==='Authorization failed'){
            sessionStorage.clear();
            window.location.href='/signin'
          }          
          else{
            this.setState({
              showModal2:true,
              modalMsg:res.data,
              disabled:false,
              msg:''
            })
          }
        })
        .catch(err=>{
          // console.log(err)
        })
    }
    else{
        this.setState({
            msg:"Please enter your private key aand passphrase",
            disabled:false
        })
    }
      }
    render() {
         const { currentWordIndex, words } = this.state;
        // console.log(new Date().toLocaleDateString('en-US'))
        return (
            <>
            <div className="profile">
            <Header2/>
            <br/>
            <div className="Pycenter">
               {this.state.tokenDetails==='Payment Expired'?
               <div className="CardP">
                <p className='text-center' style={{fontWeight:"700"}}>{this.state.tokenDetails}</p>   
                </div>
                :
                <>
                 <div className="CardP">

<div className="row pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>
<div className="col-6 p-0">
<span style={{color:'#4f1da1',fontWeight:'700',fontSize:'22px'}}>Import Wallet</span>
</div>
<div className="col-6 p-0" style={{textAlign:'right'}}>
<span style={{color:'white',fontSize:'22px',fontWeight:'600'}}>Issuer : </span>
<span  style={{color:'#3F1585',fontSize:'22px',fontWeight:'600'}}> AIFI</span>
    </div>
</div>


<div className="row mt-5 ">
    <div className="col-md-6 col-sm-12">
    <form>
        <div className="form-group">
             <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Private Key</label>
             <input type="password" name="privateKey" onChange={this.handleChange} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your private key"/>

        </div>
    </form>
    </div>
    <div className="col-md-6 col-sm-12">
    <form>
    <div className="form-group">
     <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>Passphrase</label>
    <input type="password" name="password" onChange={this.handleChange} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your passphrase"/>
    </div>
    </form>
    </div>
</div>

<div className="row mt-3 ">
    <div className="col-md-6 col-sm-12">
    <form>
        <div className="form-group">
             <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Date</label>
             <div>


                 <Form.Group controlId="dob" style={{width:''}}>
                 <Form.Control type="" name="dob" placeholder={this.state.date} style={{width:'100%',cursor:''}} disabled/>
                </Form.Group>

  
             </div>

        </div>
    </form>
    </div>
    <div className="col-md-6 col-sm-12">
    <form>
    <div className="form-group">
     <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>Amount</label>
    <input type="" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={this.state.tokenDetails?this.state.tokenDetails.decodedTokenForFees.fees:''} disabled/>
    </div>
    </form>
    </div>
</div>
<br/>
<p style={{fontSize:'18px',marginBottom:'0px'}}>It may take a few moments for this transaction to appear in your account</p>
</div>

<center style={{marginTop:'40px'}}>
    {this.state.disabled?
        <button className="ButtonErc20" style={{backgroundColor:"grey",cursor:"unset"}} disabled>
          Finish and Pay    
<img src={ercbtn} style={{height:'4vh',marginLeft:'20px'}}/>
</button>
:
<button className="ButtonErc20" onClick={this.handleSubmit}>Finish and Pay    
<img  src={ercbtn} style={{height:'4vh',marginLeft:'20px'}}/>
</button>
}
<p>{this.state.msg}</p>
{/* <br/> */}
<p style={{fontSize:'18px',fontWeight:'500',marginBottom:'0px',marginTop:'15px'}}>Need a new wallet? <a href="https://faucet.sharkwallet.app/" target='_blank' style={{color:'#4f1da1'}}><u>Create it here</u></a></p>
</center>


                </>
            }
               
               
            </div>
            </div>
            <Footer/>
 
            <Modal show={this.state.showModal2} size="lg" onHide={() => this.setState({ showModal2: false })}>
          <Modal.Body style={{ backgroundColor: '#0c0c1f', color: '#d2d2d3', padding: '8%' }}>
            <img src={close} style={{ position: 'absolute', right: 0, top: 0, fontSize: '20px', cursor: 'pointer', height: '5vh' }} onClick={() => this.setState({ showModal2: false })} />
            <div className="transaction-info anim">
              <pre style={{padding:'3%'}}>
                 {/* {words.slice(0, currentWordIndex).map((word, index) => (
                  <span key={index} className={index > 1 || word === ':' || word === '{' ? 'blue-text' : 'red-text'}>
                    {word}&nbsp;
                  </span>
                ))}  */}
        
          <p className="anim" ><span style={{color:'#FF2E2E'}}>"note"</span> : <span style={{color:' #60A3D9'}}>"Token deployed successfully."</span> ,</p>
        <p className="anim"><span style={{color:'#FF2E2E'}}>"TxnData"</span> : <span style={{color:' #60A3D9'}}></span>,</p>
        <p className="anim"><span style={{color:'#FF2E2E'}}>"Owner"</span> : <span style={{color:'#60A3D9'}}>"{this.state.modalMsg?this.state.modalMsg.owner:''}"</span></p>
        <p className="anim"><span style={{color:'#FF2E2E'}}>"TokenAddress"</span> : <span style={{color:' #60A3D9'}}>"{this.state.modalMsg?this.state.modalMsg.tokenAddress:''}"</span>,</p>
        <p className="anim"><span style={{color:'#FF2E2E'}}>"TokenName"</span> : <span style={{color:' #60A3D9'}}>"{this.state.modalMsg?this.state.modalMsg.tokenName:''}"</span></p>
        <p className="anim"><span style={{color:'#FF2E2E'}}>"TokenSymbol"</span> : <span style={{color:' #60A3D9'}}>"{this.state.modalMsg?this.state.modalMsg.tokenSymbol:''}"</span>,</p>
        <p className="anim"><span style={{color:'#FF2E2E'}}>"TokenDecimal"</span> :<span style={{color:' #60A3D9'}}> "{this.state.modalMsg?this.state.modalMsg.decimal:''}"</span>,</p>
        <p className="anim"><span style={{color:'#FF2E2E'}}>"Timestamp"</span> :<span style={{color:' #60A3D9'}}> "{this.state.modalMsg?this.state.modalMsg.timestamp:''}"</span>,</p>
        <p className="anim"><span style={{color:'#FF2E2E'}}>"TransactionID"</span> : <span style={{color:' #60A3D9'}}>{this.state.modalMsg?this.state.modalMsg.transactionID:''}</span>,</p>

              </pre>
              <center>
                <button onClick={() => this.setState({ showModal2: false })}  style={{backgroundColor:'#3F1585',color:'white',minWidth:'8rem',minHeight:'5vh',border:'none',borderRadius:'.25rem',cursor:'pointer'}}>Close</button>
              </center>
            </div>
          </Modal.Body>
        </Modal>
        <ReCAPTCHA
    sitekey="6LfdhT4pAAAAABS4bfqKSS3vByEJorSz-Aen3Aji"
    onChange={this.captchaChange}
    size="invisible"
    ref={this._reCaptchaRef}
 
  />
            </>
        )
    }
}