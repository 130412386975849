import React, { Component } from 'react';
import axios from 'axios';

class Verification extends Component {

state={
msg:'Please Wait...'
}

componentDidMount(){  
var token = window.location.href.split('?')[1]
axios.post("https://deploy.sharkweb.com/api/activateaccount",{token:token})
.then(res=>{
if(res.data.user){
this.setState({
msg:'Verified Successfully'
})
setTimeout(() => {window.location.href="/signin"},2000);
}
else if(res.data==='Something went wrong while creating user profile'){
    this.setState({
        msg:res.data
        })
}
else if(res.data==='Unable to create user'){
    this.setState({
        msg:res.data
        })
}
else{
this.setState({
msg:"Verified Successfully"
})
setTimeout(() => {window.location.href="/signin"},2000);
}
})
.catch(err=>console.log(err))
}
render() {
return (
<center><h1>{this.state.msg}</h1></center>
);
}
}

export default Verification;