import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form, Row, Col , Navbar ,Modal, Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import Header2 from './Header/Header2';
import Footer from './Footer';

import './Api Keys/sidebar.css'
import ercbtn from '../Images/ercbtn.png'
import Logo from '../Images/logo5.png';
import close from '../Images/close.png';
import axios from 'axios';
export default class ApiList extends Component {

    constructor(props, ...args) {
        super(props, ...args);
    this.state = {
        activeTab: 'generateApi',
        showQR:false,
        hoveredItemId: null,
        showModal: false,
        showModal2:false,
        apis:[]
    };
}

    componentDidMount(){
        let token = sessionStorage.getItem("token")
        let email = sessionStorage.getItem("email")
        if(!token || !email){
            window.location.href="/signin"
        }
        else{
          this.setState({
            token:token,
            email:email
          })
          const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
       
          axios.get("https://deploy.sharkweb.com/api/validateToken",config)
          .then(res=>{
            if(res.data==='User validation failed'){
              sessionStorage.clear();
              window.location.href='/signin'
            }
            else{
                axios.post("https://deploy.sharkweb.com/api/getAPIKeys",{
                    email:email
                },config)
                .then(data=>{
                    this.setState({
                        apis:data.data
                    })
                })
            }
          })
        }
      }
    handleTabClick = (tab) => {
        this.setState({ activeTab: tab });
    }
    handleMouseEnter = (id) => {
        this.setState({ hoveredItemId: id });
    };

    handleMouseLeave = () => {
        this.setState({ hoveredItemId: null });
    };
    
    handleShowModal=()=>{
        this.setState({showModal:true});
    };
    handleShowModal2=()=>{
        this.setState({showModal2:true});
    }
   
    renderContent = () => {
        
       
        const data = [
            { id: 1, column11: 'John Doe',column12:'@gmail.com', column2: ' 29-10-2023', column3: 'a6d6058-511e-45b9-87ac-dfae48', column4: 'Client API Key', column5: 'Custom Support',column6:'' },
            { id: 2, column11: 'John Doe',column12:'johndoe45@gmail.com', column2: ' 29-10-2023', column3: 'a6d6058-511e-45b9-87ac-dfae48', column4: 'Client API Key', column5: 'Custom Support',column6:'' },
            { id: 3, column11: 'John Doe',column12:'johndoe45@gmail.com', column2: ' 29-10-2023', column3: 'a6d6058-511e-45b9-87ac-dfae48', column4: 'Client API Key', column5: 'Custom Support',column6:'' },
           
        ];
      
    if (this.state.activeTab === 'generateApi') {
        return (
            <div>
                <p style={{fontSize:'28px',fontWeight:'600',marginBottom:'5px'}}>API Keys</p>
                <p>APl keys can be used to create, read, update and delete your data. Generate a key below and see our <Link to="" style={{color:'#bc65ff'}}>APl docs</Link> to get started.</p>
                <br/>
                <Link to="/generate-api-keys">  
                <button className="ButtonErc20" onClick={this.handleShowModal}>
Generate API Key
<img  src={ercbtn} style={{height:'4vh',marginLeft:'20px'}}/>
</button>
</Link>
<br/>
<br/>
<div className="table-responsive " style={{backgroundColor:'#13132e',padding:'',borderRadius:'.5rem'}}>
<table className="table  table-borderless mb-0">
    <thead>
        <tr style={{borderBottom:'1px solid #222133'}}>
       
            <td>ID</td>
            <td>API Key</td>
            <td>Created At</td>
            
            <td>Key Type</td>
            <td>Label</td>
            <td></td>
        </tr>
    </thead>
    <tbody>
    {data.map((item) => (
                    <tr key={item.id} style={{fontSize:'14px',borderBottom:'1px solid #222133'}}>
                         
                        <td >{item.column11}<br/>
                        <p style={{color:'grey',marginBottom:'0px'}}>{item.column12}</p></td>
                        <td style={{color:'grey'}}>{item.column2}</td>
                        <td style={{color:'#bc65ff'}}><i className="fa fa-eye-slash" style={{marginRight:'5px',cursor:'pointer'}}></i>{item.column3}</td>
                        <td style={{color:'grey'}}>{item.column4}</td>
                        <td >{item.column5}</td>
                        <td  style={{cursor:'pointer'}} onMouseEnter={() => this.handleMouseEnter(item.id)}
                                        onMouseLeave={this.handleMouseLeave}
                                        onClick={() => this.handleMouseEnter(item.id)}> ...
     {this.state.hoveredItemId === item.id &&  (
      <div
        style={{
          display: 'block',
          position: 'absolute',
          minHeight:'',
          backgroundColor: '#09091c',
        border: '1px solid #222133',
        padding:'1%',
        right:'5%',
        borderRadius:'.25rem',
        minWidth:'9rem',
        
        }}
      >
      
       <p onClick={this.handleShowModal2} style={{color:'#bc65ff',marginBottom:'5px',cursor:'pointer'}}>Edit</p>
       <span style={{cursor:'pointer'}}>Remove API Key</span>
      </div>
    )}
    </td>
                     
                    </tr>
                ))}
    </tbody>
</table>

</div>
            </div>
        );
    } else if (this.state.activeTab === 'getNodeInfo') {
        return (
            <div>
                Your content for the getNodeInfo tab
            </div>
        );
    }
    else if (this.state.activeTab === 'burnAifi') {
        return (
            <div>
                Your content for the getBurnAIFI tab
            </div>
        );
    } else if (this.state.activeTab === 'smartContract') {
        return (
            <div>
                Your content for the smartContract tab
            </div>
        );
    }
    else if (this.state.activeTab === 'overview') {
        return (
            <div>
                Your content for the overview tab
            </div>
        );
    } else if (this.state.activeTab === 'dex') {
        return (
            <div>
                Your content for the dex tab
            </div>
        );
    }
}
    render() {
        const { showQR } = this.state;
        const { hoveredItemId ,showModal,showModal2} = this.state;
        // console.log("hihiii",this.state.apis)
        return (
            <>
            <div className="ApiHead" style={{backgroundColor:'#09091c',padding:'2%'}}>
                
            <Header2/>
            </div>
          <hr style={{backgroundColor:'#1c1b28',margin:'0px'}}/>
<div className="row w-100 m-0">
    <div className="col-md-3 col-sm-12 p-0">
    <div className="sidebar">
         <p style={{marginLeft:'25px',fontWeight:'600'}}><u>SharkWeb VI API</u></p>
           
            <a className={this.state.activeTab === 'generateApi' ? "active" : ""} onClick={() => this.handleTabClick('generateApi')}>Api Keys</a>
        

                   
    </div>
    </div>
    <div className="col-md-9 col-sm-12 p-0">
    <div className="content">
                    {/* {this.renderContent()} */}
                    <div className="table-responsive " style={{backgroundColor:'#13132e',padding:'',borderRadius:'.5rem'}}>
<table className="table  table-borderless mb-0">
    <thead>
        <tr style={{borderBottom:'1px solid #222133'}}>
       
            {/* <td>ID</td> */}
            <td>API Key</td>
            <td>Purpose</td>
            <td>Allowed Calls</td>
            {/* <td>Key Type</td> */}
            <td>Label</td>
            <td>Status</td>
        </tr>
    </thead>
    <tbody>
    {this.state.apis.map((item) => (
                    <tr key={item._id} style={{fontSize:'14px',borderBottom:'1px solid #222133'}}>
                         
                        {/* <td >{item._id.slice(0,2)+'..'+item._id.slice(2,4)}</td> */}
                        <td>{item.api_key}</td>
                        <td>{item.purpose}</td>
                        <td>{item.expiry}</td>
                        <td>{item.label}</td>
                        <td>{item.status}</td>
                        {/* <td style={{color:'grey'}}>{item.column2}</td>
                        <td style={{color:'#bc65ff'}}><i className="fa fa-eye-slash" style={{marginRight:'5px',cursor:'pointer'}}></i>{item.column3}</td>
                        <td style={{color:'grey'}}>{item.column4}</td>
                        <td >{item.column5}</td>
                        <td  style={{cursor:'pointer'}} onMouseEnter={() => this.handleMouseEnter(item.id)}
                                        onMouseLeave={this.handleMouseLeave}
                                        onClick={() => this.handleMouseEnter(item.id)}> ...
     {this.state.hoveredItemId === item.id &&  (
      <div
        style={{
          display: 'block',
          position: 'absolute',
          minHeight:'',
          backgroundColor: '#09091c',
        border: '1px solid #222133',
        padding:'1%',
        right:'5%',
        borderRadius:'.25rem',
        minWidth:'9rem',
        
        }}
      >
      
       <p onClick={this.handleShowModal2} style={{color:'#bc65ff',marginBottom:'5px',cursor:'pointer'}}>Edit</p>
       <span style={{cursor:'pointer'}}>Remove API Key</span>
      </div>
    )}
    </td> */}
                     
                    </tr>
                ))}
    </tbody>
</table>
</div>
                </div>
        </div>
</div>
              <div style={{ marginLeft: '' }}>
              <Footer />
              </div>
              <Modal show={showModal2} size='lg' onHide={() => this.setState({ showModal2: false })}>
                  
                  <Modal.Body style={{backgroundColor:'#0c0c1f',color:'#d2d2d3',padding:'8%'}}>
                  <img src={close} style={{position:'absolute',right:0,top:0,fontSize:'20px',cursor:'pointer',height:'5vh'}} onClick={() => this.setState({ showModal2: false })} />
                      <p style={{color:'white',fontSize:'28px',fontWeight:'600'}}>Edit API Key</p>
                      <br/>
           <div className="row m-0"style={{backgroundColor:'#13132e',padding:'3%',borderRadius:'.25rem'}}>
            <div className="col-md-6 col-sm-12 p-4">
            <label style={{fontWeight:'600',color:'white'}}>Label</label>
<select className="form-select mb-3" aria-label="Default select example"  style={{ width: '100%',color:'white', backgroundColor: 'transparent', height: '5.5vh' ,border:'1px solid #34343d',fontSize:'15px',paddingLeft:'2.5%', backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' fill=\'white\' stroke=\'white\' stroke-width=\'2\' viewBox=\'0 0 24 24\'><path d=\'M6 9l6 6 6-6\'/></svg>")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 10px center',}}>
<option selected>Custom Report</option>
<option value="1">One</option>
<option value="2">Two</option>
<option value="3">Three</option>
              </select>
            </div>
            <div className="col-md-6 col-sm-12 p-4">
            <label style={{fontWeight:'600',color:'white'}}>Select Key Type</label>
<select className="form-select" aria-label="Default select example"  style={{ width: '100%',color:'#3F1585', backgroundColor: 'transparent', height: '5.5vh' ,border:'none',borderBottom:'1px solid #3F1585',fontSize:'15px',paddingLeft:'2.5%',     backgroundImage: 'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'24\' height=\'24\' fill=\'%23aa3efe\' stroke=\'%23aa3efe\' stroke-width=\'2\' viewBox=\'0 0 24 24\'><path d=\'M6 9l6 6 6-6\'/></svg>")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 10px center',}}>
<option selected>Client API Key </option>
<option value="1">One</option>
<option value="2">Two</option>
<option value="3">Three</option>
              </select>
                </div>
            </div>    
<div>


       </div>
       <br/>  <br/>
         
           <Button variant="secondary" onClick={() => this.setState({ showModal2: false })}
                         style={{minWidth:'7rem',marginRight:'15px',backgroundColor:'#3F1585',border:'1px solid #3F1585',fontWeight:'500'}}>
                          
                          Save
                      </Button>
                      <Button variant="secondary" onClick={() => this.setState({ showModal2: false })}
                      style={{minWidth:'7rem',marginRight:'',border:'1px solid #3F1585',backgroundColor:'transparent'}}>
                          Cancel
                      </Button>
                     
                 
                  </Modal.Body>
                 
              </Modal>

            </>
        )
    }
}