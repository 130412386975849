import React, { Component, Fragment} from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form, Row, Col , Navbar , Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import Header from './Header/Header1';
import ercbtn from '../Images/ercbtn.png'
import s21 from '../Images/mdi_account-plus.png'
import s22 from '../Images/ooui_view-details-ltr.png'
import s23 from '../Images/clarity_deploy-solid.png'
import s41 from '../Images/government of india.png'
import s42 from '../Images/Government of Madhya Pradesh logo.png'
import s43 from '../Images/Ordnance Factory Itarsi.png'
import s44 from '../Images/RIPPLE LOGO.png'
import s45 from '../Images/speedy comics logo.png'
import check1 from '../Images/check1.png'
import s1 from '../Images/ss1.png'
import Footer from './Footer';
import bullet7 from '../Images/bullet point.png'
import shark7 from '../Images/sharkweb protocol.png'
import sec8 from '../Images/sec8main.png'
import square from '../Images/square.png'
import wallet from '../Images/iconoir_wallet.png'
import arrow from '../Images/arrow.png'
import sec9 from '../Images/sec9.png'
import sec10 from '../Images/sec10.png'
import landinggif from '../Images/Land.gif'
import CountUp from 'react-countup';
import axios from 'axios';

export default class Landing extends Component {
    constructor(props, ...args) {
        super(props, ...args);
    this.state={
        totalTxns:'',
        totalWallet:'',
        lastThreeBlock:[]
    }
}
    componentDidMount= async()=>{
       await axios.post("https://ipv4.sharkweb.com/totalTxn",{secret:"BD773BJU!^#T^FDG"})
        .then(res=>{
          this.setState({
            totalTxns:res.data
          })
        })
        await axios.get("https://ipv4.sharkweb.com/totalWallet")
        .then(res=>{
          this.setState({
            totalWallet:res.data
          })
        })
        await axios.get("https://cron.sharkweb.com/getBlocks_50")
        .then(res=>{
            let localTransaction = []
            res.data.map(async(val)=>{
              // console.log(val)
              if(val.transactions.length>0){
                  val.transactions.map(async(nestedIndex)=>{
                    try{
                    if(nestedIndex.recipient.length==39 && nestedIndex.sender.length==39){
                      await localTransaction.push({txns:nestedIndex,height:val.index,type:'Transfer'})
                    }
                    else if(nestedIndex.recipient.length==39 && nestedIndex.sender.length==16){
                      await localTransaction.push({txns:nestedIndex,height:val.index,type:'Mint'})
                    }
                    else if(nestedIndex.recipient.length==6){
                      await localTransaction.push({txns:nestedIndex,height:val.index,type:'Burn'})
                    }
                    else{
                      await localTransaction.push({txns:nestedIndex,height:val.index,type:''})
                    }
                  }
                  catch{
                    await localTransaction.push({txns:nestedIndex,height:val.index,type:'Data'})
                  }
                  })
              }
            })
            if(res.data.length>0){
              let lastThreeB = []
              for(let i=res.data.length-1;i>(res.data.length-1)-1;i--){
                lastThreeB.push(res.data[i])
                // console.log(last)
                const lastElement =  lastThreeB[lastThreeB.length-1]
                this.setState({
                  lastThreeBlock:lastElement.index
                })
              }
            }
            if(res.data.length>0){
              let lastFiveB = []
              for(let i=res.data.length-1;i>(res.data.length-1)-6;i--){
                lastFiveB.push(res.data[i])
                this.setState({
                  blocks:lastFiveB
                })
              }
            }
            if(localTransaction.length>0){
              let lastFiveT = []
              for(let i=localTransaction.length-1;i>(localTransaction.length-1)-6;i--){
                lastFiveT.push(localTransaction[i])
                this.setState({
                  transactions:lastFiveT
                })
              }
            }
            this.setState({
              Loader:''
            })
          })
    }
   

    render() {
        return (
            <>

               <div className="section1">
                <Header/>
                
                <section1>
                <div style={{paddingTop:'5%'}}>
                    <div className="row">
                        <div className="col-md-7">
                        <h1 style={{fontWeight:"500",fontSize:"40px"}}>No-Code Token Generator</h1>
                        <br/>
                        <p className="" style={{fontSize:'18px'}}>Create a Token in less than a minute with No-Code Token Generator. 
                        <br/>
                         No coding required.</p>
                        <br/>
                        <div style={{paddingTop:''}}>
    <Link to="/signin">
<button className="ButtonErc20">
    Create a Token 
    <img  src={ercbtn} style={{height:'4vh',marginLeft:'20px'}}/>
</button>
</Link>
{/* 
<button className="ButtonPricing">Pricing</button> */}
                        </div>
                        </div>
                        <div className="col-md-5">
                                <Fragment>
                        <img  className="iheight" style={{width:"100%",height:"auto"}} src={landinggif}/>
                        </Fragment>
                        </div>
                   </div>
                </div>
                </section1>


                <section className="section2">
                <center>
                    <h1 className="section2Heading">How It Works</h1> 
                    <p className="sectionPara2">Effortlessly deploy the Smart SharkCode Contract for Standard, Capped, Mintable,
                    Burnable, Payable Tokens by adhering to the following three steps
                    </p>
                    <div className="row" style={{fontSize:'17px',marginTop:'5%'}}>
                        <div className="col-md-4 col-sm-12 text-center">
                            <img src={s21} style={{height:'8vh'}}/>
                            <br/>
                            <br/>
                            <p style={{fontSize:'18px'}}>
                                <span style={{marginRight:'5px'}}>Step 1 :</span>
                                <span style={{fontWeight:'700'}}>Create an Account</span>
                            </p>
                            <p> You need to have SharkWallet installed with the amount of AIFI to pay for contract deployment. </p>
                        </div>

                        <div className="col-md-4 col-sm-12 text-center">
                            <img src={s22} style={{height:'8vh'}}/>
                            <br/>
                            <br/>
                            <p style={{fontSize:'18px'}}>
                                <span style={{marginRight:'5px'}}>Step 2 :</span>
                                <span style={{fontWeight:'700'}}>Enter Details</span>
                            </p>

                            <p> Enter your preferred Token name & symbol. Choose your supply and Token type. </p>
                        </div>


                        <div className="col-md-4 col-sm-12 text-center">
                            <img src={s23} style={{height:'8vh'}}/>
                            <br/>
                            <br/>
                            <p style={{fontSize:'18px'}}>
                                <span style={{marginRight:'5px'}}>Step 3 :</span>
                                <span style={{fontWeight:'700'}}>Deploy Token</span>
                            </p>

                            <p> Confirm your transaction using SharkWallet. Once deployed your Token is ready to use.</p>
                        </div>
                    </div>


                 
                    <a href="/signin">
                    <button className="ButtonStart">
                        Get Started
                        <img src={ercbtn} style={{height:'4vh',marginLeft:'20px'}}/>
                    </button>
                    </a>

                </center>    
                </section>
               </div>
          

               {/* <div className="section3">
               <center>
                    <h1 className="section2Heading">Pricing</h1> 
                </center>
                    <br/> <br/>
                    <div className="cardWhite">
                        <div className="row">
                            <div className="col-md-3 col-sm-12 ">
                                <h2 style={{fontWeight:'700',marginBottom:'15px',color:'black'}}>0 AIFI</h2>
                                
                                <p style={{fontSize:'24px',fontWeight:'700',color:'black',marginBottom:'15px'}}>Intro</p>
                                <p style={{marginBottom:'15px'}}>For most businesses that want to otbimize web aueries</p>
                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    All limited links 
                                </p>

                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    Own analytics platform
                                </p>

                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    Chat support 
                                </p>

                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    Optimize hashtags 
                                </p>
                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    Unlimited users 
                                </p>
                                <button className="buttonChoose">Choose Plan</button>
                            </div>
                            <div className="col-md-3 col-sm-12 Mtop30">
                                <h2><span style={{fontWeight:'700',marginBottom:'15px',color:'black',marginRight:'5px'}}>20 AIFI</span>
                                    <span style={{fontStyle:'italic',fontSize:'18px',fontWeight:'400'}}>/ month</span></h2>
                                
                                <p style={{fontSize:'24px',fontWeight:'700',color:'black',marginBottom:'15px'}}>Base</p>
                                <p style={{marginBottom:'15px'}}>For most businesses that want to otbimize web aueries</p>
                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    All limited links 
                                </p>

                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    Own analytics platform
                                </p>

                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    Chat support 
                                </p>

                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    Optimize hashtags 
                                </p>
                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    Unlimited users 
                                </p>
                                <center>
                                <button className="buttonChoose">Choose Plan</button>
                                </center>
                                
                            </div>
                            <div className="col-md-3 col-sm-12 pl-0 Mtop30 pd0">
                            <div className="cardAbs">
                            <div className="" style={{backgroundColor:'#3F1585'}}>
                            <button className="buttonPop">MOST POPULAR</button>
                            <br/>
                            <h2 style={{marginTop:'10px'}}><span style={{fontWeight:'700',marginBottom:'15px',color:'white',marginRight:'5px',marginTop:''}}>50 AIFI</span>
                                    <span style={{fontStyle:'italic',fontSize:'18px',fontWeight:'400'}}>/ month</span></h2>
                                
                                <p style={{fontSize:'24px',fontWeight:'700',color:'white',marginBottom:'15px'}}>Pro</p>
                                <p style={{marginBottom:'15px'}}>For most businesses that want to otbimize web aueries</p>
                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    All limited links 
                                </p>

                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    Own analytics platform
                                </p>

                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    Chat support 
                                </p>

                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    Optimize hashtags 
                                </p>
                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    Unlimited users 
                                </p>
                                <button className="buttonChoose" style={{backgroundColor:'white',color:'#3F1585'}}>Choose Plan</button>
                                <br/>
                                <p></p>
                            </div>
                            </div>
                            </div>
                            <div className="col-md-3 col-sm-12 Mtop30">
                            <h2><span style={{fontWeight:'700',marginBottom:'15px',color:'black',marginRight:'5px'}}>90 AIFI</span>
                                    <span style={{fontStyle:'italic',fontSize:'18px',fontWeight:'400'}}>/ month</span></h2>
                                
                                <p style={{fontSize:'24px',fontWeight:'700',color:'black',marginBottom:'15px'}}>Enterprise</p>
                                <p style={{marginBottom:'15px'}}>For most businesses that want to otbimize web aueries</p>
                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    All limited links 
                                </p>

                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    Own analytics platform
                                </p>

                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    Chat support 
                                </p>

                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    Optimize hashtags 
                                </p>
                                <p style={{marginBottom:'10px'}}>
                                    <img src={check1} style={{height:'3vh',marginRight:'10px'}}/>
                                    Unlimited users 
                                </p>
                                <button className="buttonChoose">Choose Plan</button>
                            </div>

                        </div>
                    </div>
                    <br/>
               </div> */}

               <div className="section4">
                <div className="row">
                    <div className="col-md-12 col-lg-3 col-sm-12 CenterAlign">
                        <p className="mt-3" style={{fontSize:'18px'}}>Trusted by the world’s most
                        innovative businesses
                        </p>
                    </div>
                    <div className="col-md-12 col-lg-9 col-sm-12 mt-3">
                        <div className="row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="col-md-3 col-sm-12 Mtop30 text-center">
                            <img className="H3_5" src={s41} style={{height:'10vh'}}/>
                            </div>
                            <div className="col-md-2 col-sm-12 Mtop30 text-center">
                            <img className="H4" src={s42} style={{height:'10vh'}}/>  
                            </div>
                            <div className="col-md-2 col-sm-12 Mtop30 text-center">
                            <img className="H2_4" src={s43} style={{height:'10vh'}}/>  
                            </div>
                            <div className="col-md-3 col-sm-12 Mtop30 text-center">
                            <img className="H2_4" src={s44} style={{height:'7vh'}}/>  
                            </div>
                            <div className="col-md-2 col-sm-12 Mtop30 CenterAlign">
                            <img className="H2_4" src={s45} style={{height:'9vh'}}/>  
                            </div>
                        </div>
                    </div>
                </div>

               </div>
               <div className='section7'>
                <div className='row'>
                    <div className='col-md-7 col-sm-12'>
                        <h1>Sharkweb Protocol</h1>
                        {/* <h4 style={{color:"#8C4BF9",fontWeight:"600",}}>Powering Blockchains Since 2017</h4> */}
                        <p style={{color:"#E1E1E1",fontWeight:"400",fontSize:"20px",}}>SharkWeb Blockchain is not just another  LAYER 1 blockchain technology - <br/>it's an entirely new approach involving AI and Cyber security.</p>
                        <div className="sec7img">
                            <img  src={bullet7} style={{height:'5vh',marginRight:"10px"}}/>
                            <span style={{color:"#C3C3C3;",fontSize:"18px",}}>Self-Expanding Block Size</span><br/>
                            <img  src={bullet7} style={{height:'5vh',marginRight:"10px"}}/>
                            <span style={{color:"#C3C3C3;",fontSize:"18px",}}>SharkCode Contracts And SharkScale</span><br/>
                            <img  src={bullet7} style={{height:'5vh',marginRight:"10px"}}/>
                            <span style={{color:"#C3C3C3;",fontSize:"18px",}}>Smarter Chains And Side Chains</span>
                        </div>
                        <a href="https://docs.sharkweb.com/" target='_blank'>
                            <button class="ButtonStart-bullet">
                                Documentations
                                {/* <img src={ercbtn}  style={{height:'4vh',marginLeft:'20px'}}/> */}
                            </button>
                         </a>
                    </div>
                        <div className='col-md-5 col-sm-12'>
                            <div className="Sharkleft">
                                <img  src={shark7} className='img-fluid' style={{}}/>
                            </div>
                        </div>
                </div>
               </div>

               <div className="section8">
                    <div className="row">
                        <div className="col-md-6">
                            <center>
                                <img  src={sec8} className='img-fluid' style={{}}/>
                            </center>
                        </div>
                        <div className="col-md-6">
                            <h1 style={{fontSize:"40px",fontWeight:"700",color:"#fff"}}>The Sharkweb <span style={{color:'#8C4BF9'}} >Network</span></h1>
                            <p style={{color:"#8C4BF9",fontSize:"26px",fontWeight:"600"}}>Home of the Sharkweb Token</p>
                           <div className="row">
                            <div className="col-md-4">
                                <img src={square} style={{width:"20px",height:"20px"}}/>
                             
                            <p style={{color:"#fff",fontSize:"35px",fontWeight:"500"}}><CountUp start={0} duration={5} end={this.state.lastThreeBlock}/></p>

                                <hr className='foo8'/>
                                <p style={{color:"#6E6E70",fontSize:"14px",fontWeight:"600"}}>Blocks Produced</p>
                            </div>
                            <div className="col-md-4">
                                <img src={arrow} style={{width:"20px",height:"20px"}}/>
                                <p style={{color:"#fff",fontSize:"35px",fontWeight:"500"}}><CountUp start={0} duration={5} end={this.state.totalTxns}/></p>
                                <hr className='foo8'/>
                                <p style={{color:"#6E6E70",fontSize:"14px",fontWeight:"600"}}>Transaction Processed</p>
                            </div>
                            <div className="col-md-4">
                                <img src={wallet} style={{width:"20px",height:"20px"}}/>
                                <p style={{color:"#fff",fontSize:"35px",fontWeight:"500"}}><CountUp start={0} duration={5} end={this.state.totalWallet}/></p>
                                <hr className='foo8'/>
                                <p style={{color:"#6E6E70",fontSize:"14px",fontWeight:"600"}}>Wallet Created</p>
                            </div>
                            </div>
                            {/* <p style={{color:"#fff",fontSize:"18px",fontWeight:"400"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore et dolore magna</p> */}
 
                            <a href="https://sharkscan.com/" target='_blank'>
                            <button class="ButtonStart-bullet-exp">
                                Explorer
                                {/* <img src={ercbtn}  style={{height:'4vh',marginLeft:'20px'}}/> */}
                            </button>
                         </a>
                        </div>
                    </div>
               </div>

               <div className="section9">
                <div className="row">
                    <div className="col-md-7 col-sm-12">
                        <p style={{color:"fff",fontSize:"40px",fontWeight:"700"}}>Where to get <span style={{color:"#7739DE"}}>AIFI</span></p>
                        {/* <p style={{color:"#E1E1E1",fontSize:"20px",fontWeight:"400"}}>SharkWeb is an Open-Source, Typescript-Based, Layer 1 Blockchain Protocol built to be simple and efficient</p> */}
                        <a href="https://faucet.sharkwallet.app/" target='_blank'>
                            <button class="ButtonStart-bullet">
                                Get AIFI 
                                {/* <img src={ercbtn}  style={{height:'4vh',marginLeft:'20px'}}/> */}
                            </button>
                         </a>
                    </div>
                    <div className="col-md-5 col-sm-12">
                        <center>
                         <img  src={sec9} className='img-fluid' style={{}}/>
                        </center>
                    </div>
                </div>
               </div>

               <div className="section10">
                    <div className="row">
                            <div className="col-md-5 col-sm-12">
                                <img  src={sec10} className='img-fluid' style={{}}/>
                            </div>
                        <div className="col-md-7 col-sm-12">
                            <p style={{color:"#fff",fontSize:"40px",fontWeight:"700"}}>Token Deployment <span style={{color:"#7739DE"}}>Features</span></p>
                            <p style={{color:"#E1E1E1",fontSize:"20px",fontWeight:"400"}}>Only at SharkWeb, you can build a good portfolio and learn best practices about Blockchain.</p>
                            <p className='mt-4' style={{color:"#E5E5E5",fontSize:"26px",fontWeight:"500"}}>Our Features</p>
 
                            <button class="btn-our button1 mt-3">Standard</button>
                            <button class="btn-our button2 mt-3">Pausable</button>
                            <button class="btn-our button3 mt-3">Mintable</button>
                            <button class="btn-our button2 mt-2">Burnble</button>
                            <button class="btn-our button3 mt-2">Ownership Transferable</button>
                        </div>
                    </div>
               </div>

               {/* <div className="section5">
               <center>
                    <h1 className="section2Heading">Features</h1> 
                    <p style={{fontSize:'20px',marginTop:'20px'}}>You will be able to choose between several features. What are your Token requirement?</p>
                </center>
          
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <div className="cardFeature">
                            <center>
                                <p style={{color:'#154bba',fontWeight:'700',fontSize:'20px'}}>Burnable</p>
                            </center>
                                <p>Your Token can be burnt. It means that
                                   you can choose to reduce the circulating
                                   supply by destroying some of your tokens
                                </p>
                       </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                        <div className="cardFeature">
                            <center>
                                <p style={{color:'#154bba',fontWeight:'700',fontSize:'20px'}}>Detailed</p>
                            </center>
                                <p>Your Token can be burnt. It means that
                                   you can choose to reduce the circulating
                                   supply by destroying some of your tokens
                                </p>
                       </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                        <div className="cardFeature">
                            <center>
                                <p style={{color:'#154bba',fontWeight:'700',fontSize:'20px'}}>Mintable</p>
                            </center>
                                <p>Your Token can be burnt. It means that
                                   you can choose to reduce the circulating
                                   supply by destroying some of your tokens
                                </p>
                       </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <div className="cardFeature">
                            <center>
                                <p style={{color:'#154bba',fontWeight:'700',fontSize:'20px'}}>Pausable</p>
                            </center>
                                <p>Your Token can be burnt. It means that
                                   you can choose to reduce the circulating
                                   supply by destroying some of your tokens
                                </p>
                       </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                        <div className="cardFeature">
                            <center>
                                <p style={{color:'#154bba',fontWeight:'700',fontSize:'20px'}}>Ownable Access</p>
                            </center>
                                <p>Your Token can be burnt. It means that
                                   you can choose to reduce the circulating
                                   supply by destroying some of your tokens
                                </p>
                       </div>
                    </div>

                    <div className="col-md-4 col-sm-12">
                        <div className="cardFeature">
                            <center>
                                <p style={{color:'#154bba',fontWeight:'700',fontSize:'20px'}}>Token Recorver</p>
                            </center>
                                <p>Your Token can be burnt. It means that
                                   you can choose to reduce the circulating
                                   supply by destroying some of your tokens
                                </p>
                       </div>
                    </div>
                </div>
               </div> */}


               <div className="section6">
               <center>
                <p style={{fontSize:'25px',fontWeight:'600'}}>Ready to deploy your Token?</p>
                <p style={{fontSize:'18px'}}>Try building your Token in less than a minute. You have the opportunity to test it on our Testnet prior to the official launch on the Mainnet.</p>
                <Link to="/signin">
                <button className="buttonCreate">CREATE A TOKEN</button>
                </Link>
               </center>

               </div>

               <Footer/>
            </>
        )
    }
}