import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form, Row, Col , Navbar , Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import Header2 from './Header/Header2';
import Footer from './Footer';
import ercbtn from '../Images/ercbtn.png'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import axios from 'axios'
import gif from '../Images/sharkweb.gif'

import ReCAPTCHA from "react-google-recaptcha"

var CryptoJS = require("crypto-js");

export default class CreateToken extends Component {
  constructor(props, ...args) {
    super(props, ...args);
  this.state={
    token:'',
    email:'',
    tokenName:'',
    tokenSymbol:'',
    totalSupply:'',
    decimal:'',
    mintable:'',
    ownershipTransferable:'',
    pausable:'',
    burnable:'',
    tokenType:'',
    network:'',
    fees:20,
    msg:'',
    err:'',
    err1:'',
    disable:false,

     callback: "not fired",
      value: "[empty]",
      load: false,
      expired: "false",
      recaptchaLoaded: false,
      captchaToken:'',
  };
    this._reCaptchaRef = React.createRef();
}
 captchaChange=(value)=> {
    this.setState({ value });
    if (value === null) this.setState({ expired: "true" });
    this.setState({
      captchaToken:value
    })
  }
componentDidMount(){
  let token = sessionStorage.getItem("token")
  let email = sessionStorage.getItem("email")

    this._reCaptchaRef.current.execute();

        if(!token || !email){
            window.location.href="/signin"
        }
        else{
          this.setState({
            token:token,
            email:email
          })
          const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
          axios.get("https://deploy.sharkweb.com/api/validateToken",config)
          .then(res=>{
            if(res.data==='User validation failed'){
              sessionStorage.clear();
              window.location.href='/signin'
            }
          })
        }
}
handleChange = (e) =>{
  this.setState({
      [e.target.name]:e.target.value
  })
  if(e.target.name==='decimal'){
    if((parseFloat(e.target.value)>0)){
      this.setState({
        err:""
      })
    }
    else{
      this.setState({
        err:"The decimal value of a token cannot be zero or negative."
      })
    }

    
  }

  if(e.target.name==='totalSupply'){
    if((parseFloat(e.target.value)>0)){
      this.setState({
        err1:""
      })
    }
    else{
      this.setState({
        err1:"The decimal value of a total supply cannot be zero or negative."
      })
    }
}
  if(e.target.value==='Mintable'){
    this.setState({
        fees:parseFloat(this.state.fees)+10
    })
}
else if(e.target.value==='Transferable'){
    this.setState({
        fees:parseFloat(this.state.fees)+10
    })
}
else if(e.target.value==='Pausable'){
    this.setState({
        fees:parseFloat(this.state.fees)+10
    })
}
else if(e.target.value==='Burnable'){
    this.setState({
        fees:parseFloat(this.state.fees)+10
    })
}

else if(e.target.value==='Non-Burnable'){
  if(parseFloat(this.state.fees)>=30 && this.state.burnable==='Burnable'){
    this.setState({
        fees:parseFloat(this.state.fees)-10
    })
  }
}
else if(e.target.value==='Non-Pausable'){
  if(parseFloat(this.state.fees)>=30 && this.state.pausable==='Pausable'){
    this.setState({
        fees:parseFloat(this.state.fees)-10
    })
  }
}
else if(e.target.value==='Non-Transferable'){
  if(parseFloat(this.state.fees)>=30 && this.state.ownershipTransferable==='Transferable'){
    this.setState({
        fees:parseFloat(this.state.fees)-10
    })
  }
}
else if(e.target.value==='Non-Mintable'){
  if(parseFloat(this.state.fees)>=30 && this.state.mintable==='Mintable'){
    this.setState({
        fees:parseFloat(this.state.fees)-10
    })
  }
}
}
submit = async() =>{
  this.setState({
    disable:true,
    msg: <img src={gif} style={{height:'2rem',width:'2rem'}} alt="loader" />
 })
  if(this.state.decimal>0 && this.state.totalSupply>0){
    if(this.state.tokenName.length>0 && this.state.tokenSymbol.length>0 && this.state.totalSupply.length>0 && this.state.decimal.length>0
      && this.state.mintable.length>0 && this.state.ownershipTransferable.length>0 && this.state.pausable.length>0 && 
      this.state.burnable.length>0 && this.state.tokenType.length>0 && this.state.network.length>0){
       
        // localStorage.setItem("createToken",JSON.stringify(this.state))
        var fees = CryptoJS.AES.encrypt((this.state.fees).toString(),"key")
        const config = {
          headers: { Authorization: `Bearer ${this.state.token}` }
      };
       await axios.post("https://deploy.sharkweb.com/api/generateTokenCode",{
          fees:fees.toString(),
          tokenName:this.state.tokenName,
          tokenSymbol:this.state.tokenSymbol,
          totalSupply:this.state.totalSupply,
          decimal:this.state.decimal,
          mintable:this.state.mintable,
          ownershipTransferable:this.state.ownershipTransferable,
          pausable:this.state.pausable,
          burnable:this.state.burnable,
          tokenType:this.state.tokenType,
          network:this.state.network,
          email:this.state.email,
          captchaToken:this.state.captchaToken,
        },config)
        .then(res=>{
           this._reCaptchaRef.current.execute();
          if(res.data==='Authorization failed'){
            sessionStorage.clear();
            window.location.href='/signin'
          }
          else{
            window.location.href=`/payment?${res.data.token}`
          }
          // console.log(res.data)
        })
        .catch(error=>{
          if(error){
            this.setState({
              msg:"Try again",
              disable:false
            })
          }
        })
      }
    else{
      this.setState({
        msg:"Please enter all details",
        disable:false
      })
    }
  
  }
else{
  this.setState({
    msg:"Value cannot be zero or negative.",
    disable:false
  })

}


  }
    render() {
      // console.log(this.state) updated
        return (
            <>
            <div className="profile">
            <Header2/>
            <br/>
            <h1 className="token">Create your Token</h1>
            <p style={{fontSize:'20px'}}>Easily deploy Smart SharkCode Contract for a Standard, Pausable, Mintable, Burnable, Ownership Transferable. No coding required.</p>
           
            <div className="cardWhite" style={{borderRadius:'.25rem',paddingLeft:'3%',paddingRight:'3%',paddingTop:'1%',paddingBottom:'1%',fontSize:'18px',color:'black'}}>
            <i className="fa fa-exclamation-triangle" style={{color:'red',fontSize:'20px',marginRight:'10px'}}></i>
            {/* <span style={{color:'red',fontWeight:'600',marginRight:'5px'}}>Alert</span> */}
            <span> You must have a wallet created before creating token using this platform. Please visit <Link to={'https://faucet.sharkwallet.app/'} target='_blank'> https://faucet.sharkwallet.app/ </Link> for wallet creation.
</span>
            </div>
            <div className="Pycenter mt-5">
               
                <div className="CardP">

                    <div className=" pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>
                  
                    <span style={{color:'#7739de',fontWeight:'700',fontSize:'32px'}}>Token Details</span>
                   
                    </div>
                   

                    <div className="row mt-5 ">
                        <div className="col-md-6 col-sm-12">
                        <form>
                            <div className="form-group">
                                 <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Token Name</label>
                                 <input type="text" name="tokenName" onChange={this.handleChange} className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Enter your token name"/>
                                    <p className="small">Choose a name for your Token</p>
                            </div>
                        </form>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <form>
                        <div className="form-group">
                         <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>Token Symbol</label>
                         <input type="text" name="tokenSymbol" onChange={this.handleChange} className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Enter your token symbol"/>
                         <p className="small">Choose a symbol for your token </p>
                         </div>
                        </form>
                        </div>
                    </div>

                    <div className="row mt-3 ">
                        <div className="col-md-6 col-sm-12">
                        <form>
                            <div className="form-group">
                                 <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Token Decimals</label>
                                 <input type="" name="decimal" onChange={this.handleChange} className="form-control tokenI" id="exampleInputEmail1 " aria-describedby="emailHelp" placeholder="18"/>
                          
                                 <p className="small" style={{marginBottom:'0px'}}>Insert the decimal precision of your token. If you do not know, use 18 </p>
                                 <p style={{color:'red',fontSize:'13px',marginTop:'0px',marginBottom:''}}>{this.state.err}</p>
                            </div>
                        </form>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <form>
                        <div className="form-group">
                         <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>Token Supply</label>
                         <input type="" name="totalSupply" onChange={this.handleChange} className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Your token max supply"/>
                         <p className="small" style={{marginBottom:'0px'}}>Insert the maximum number of tokens available </p>
                         <p style={{color:'red',fontSize:'13px',marginTop:'0px',marginBottom:''}}>{this.state.err1}</p>
                        </div>
                        </form>
                        </div>
                    </div>
                    
                </div>
</div>

<div className="Pycenter mt-5">

                <div className="CardP">

<div className=" pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>

<span style={{color:'#7739de',fontWeight:'700',fontSize:'32px'}}>Token Features</span>

</div>


<div className="row mt-5 ">
    <div className="col-md-6 col-sm-12">
    <form>
        <div className="form-group">
             <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Supply Type</label>
             <br/>
             {/* <Form.Select aria-label="Default select example" style={{width:'100%',backgroundColor:'white',border:'none',minHeight:'6vh',padding:'2.5%',borderRadius:'.25rem'}}>
            <option >Open this select menu</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
             </Form.Select> */}
        <FormControl  variant="" style={{width:'100%'}}>
        <InputLabel id="select-label" style={{color:'black'}}>Token Features</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          className="drop"  
          onChange={this.handleChange} name="mintable" 
        >
          <MenuItem value="Mintable">Mintable</MenuItem>
          <MenuItem value="Non-Mintable">Non-mintable</MenuItem>
       
        </Select>
        </FormControl>
            <p className="small1">Mintable, Non-Mintable</p>
        </div>
    </form>
    </div>
    <div className="col-md-6 col-sm-12">
    <form>
    <div className="form-group">
     <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>Access Type</label>
     <FormControl variant="" style={{width:'100%'}}>
     
        <Select
          labelId="select-label"
          id="select"
          className="drop"   
          name="ownershipTransferable" onChange={this.handleChange}
        >
         
          <MenuItem value="Transferable">Transferable</MenuItem>
          <MenuItem value="Non-Transferable">Non-transferable</MenuItem>
        
        </Select>
      </FormControl>
     <p className="small1">None, Ownable</p>
     </div>
    </form>
    </div>
</div>

<div className="row mt-3 ">
    <div className="col-md-6 col-sm-12">
    <form>
        <div className="form-group">
             <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Contract Type</label>
             <br/>
             {/* <Form.Select aria-label="Default select example" style={{width:'100%',backgroundColor:'white',border:'none',minHeight:'6vh',padding:'2.5%',borderRadius:'.25rem'}}>
            <option >Open this select menu</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
             </Form.Select> */}
                  <FormControl variant="" style={{width:'100%'}}>
        <InputLabel id="select-label" style={{color:'black'}}>Token Features</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          className="drop"   
          onChange={this.handleChange} name="pausable"
        >
         
          <MenuItem value="Pausable">Pausable</MenuItem>
          <MenuItem value="Non-Pausable">Non-Pausable</MenuItem>
       
        </Select>
      </FormControl>
                <p className="small1">Pausable, Non-Pausable</p>
        </div>
    </form>
    </div>
    <div className="col-md-6 col-sm-12">
    <form>
    <div className="form-group">
     <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>Burn Type</label>
     <FormControl variant="" style={{width:'100%'}}>
     
        <Select
          labelId="select-label"
          id="select"
          className="drop"   
          onChange={this.handleChange} name="burnable"
        >
         
          <MenuItem value="Burnable">Burnable</MenuItem>
          <MenuItem value="Non-Burnable">Non-burnable</MenuItem>
    
        </Select>
      </FormControl>
     <p className="small1">Burnable, Non-burnable</p>
     </div>
    </form>
    </div>
</div>



                </div>

</div>
<div className="Pycenter  mt-5">
                <div className="CardP">

<div className=" pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>

<span style={{color:'#7739de',fontWeight:'700',fontSize:'32px'}}>Token Type and Network</span>

</div>


<div className="row mt-5 ">
    <div className="col-md-6 col-sm-12">
    <form>
        <div className="form-group">
             <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Token Type</label>
             <br/>
                  <FormControl variant="" style={{width:'100%'}}>
        <InputLabel id="select-label" style={{color:'black'}}>Token Features</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          className="drop"   
          onChange={this.handleChange} name="tokenType"
        >
         
          <MenuItem value="AI22">AI22</MenuItem>
          {/* <MenuItem value="option2">option 2</MenuItem> */}
       
        </Select>
      </FormControl>
                <p className="small1">Chosse your token type</p>
        </div>
    </form>
    </div>
    <div className="col-md-6 col-sm-12">
    <form>
    <div className="form-group">
     <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>Network</label>
     <FormControl variant="" style={{width:'100%'}}>
     
        <Select
          labelId="select-label"
          id="select"
          className="drop"   
          onChange={this.handleChange} name="network"
        >
         
          <MenuItem value="Testnet AI22">Testnet AI22</MenuItem>
          {/* <MenuItem value="option2">option2</MenuItem> */}
        
        </Select>
      </FormControl>
     <p className="small1">Choose your network</p>
     </div>
    </form>
    </div>
</div>
{/* <div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{width:'18px',height:'18px',marginRight:'',cursor:'pointer'}}/>
  <label className="form-check-label" for="flexCheckDefault" style={{marginLeft:'10px'}}>
  I have read, understood and agreed to Token Generator’s <Link to="" style={{color:'#3F1585'}}>Terms of Use</Link>
  </label>
</div> */}




                </div>


                <div className="Pycenter  mt-5">
                <div className="CardP">

<div className=" pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>

<span style={{color:'#7739de',fontWeight:'700',fontSize:'32px'}}>Transactions</span>

</div>


<div className="row mt-5 ">
    <div className="col-md-6 col-sm-12">
             <p style={{fontSize:'20px',color:'#b8b8b8'}}>Commission Fee : <i className="fa fa-exclamation-circle" style={{color:'white'}}></i></p>
    </div>
    <div className="col-md-6 col-sm-12" style={{float:'right',textAlign:'right'}}>
     <button disabled style={{backgroundColor:"#3F1585",border:"none",borderRadius:'.25rem',color:'white',fontWeight:'600',minWidth:'7rem',minHeight:'5vh'}}>{this.state.fees} AIFI</button>
    </div>
</div>

{/* <div className="row mt-3 ">
    <div className="col-md-6 col-sm-12">
             <p style={{fontSize:'20px',color:'#b8b8b8'}}>Gas Fee : <i className="fa fa-exclamation-circle" style={{color:'white'}}></i></p>
    </div>
    <div className="col-md-6 col-sm-12" style={{float:'right',textAlign:'right'}}>
     <button style={{backgroundColor:"#3671e9",border:"none",borderRadius:'.25rem',color:'white',fontWeight:'600',minWidth:'7rem',minHeight:'5vh',cursor:'pointer'}}>Variable</button>
    </div>
</div> */}

                </div>
</div>
                <center className="mt30" style={{marginTop:'60px'}}>
                  {/* <Link to="/payment"> */}

               {!this.state.disable ? <button className="ButtonErc20" onClick={this.submit}>Confirm    
                    <img  src={ercbtn} style={{height:'4vh',marginLeft:'20px'}}/>
                </button>:
                <button disabled className="ButtonErc20" style={{backgroundColor:'gray'}}>Confirm    
                    <img  src={ercbtn} style={{height:'4vh',marginLeft:'20px'}}/>
                </button>}

                <p className='text-center' style={{color:this.state.msg==='Try again'?'red':'',fontSize:'14px'}}>{this.state.msg}</p>
             
                {/* </Link> */}
               </center>
               
            </div>
            </div>
            <Footer/>
 
          <ReCAPTCHA
    sitekey="6LfdhT4pAAAAABS4bfqKSS3vByEJorSz-Aen3Aji"
    onChange={this.captchaChange}
    size="invisible"
    ref={this._reCaptchaRef}
 
  />
            </>
        )
    }
}