import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form,Modal, Row, Col , Navbar , Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import Header2 from './Header/Header2';
import Footer from './Footer';
import ercbtn from '../Images/ercbtn.png'
import axios from 'axios';
import close from '../Images/close.png';
import gif from '../Images/sharkweb.gif'
import ReCAPTCHA from "react-google-recaptcha"
export default class Payment extends Component {

  constructor(props, ...args) {
    super(props, ...args);
  this.state={
    showModal2:false,

     callback: "not fired",
     value: "[empty]",
     load: false,
     expired: "false",
     recaptchaLoaded: false,
     captchaToken:'',
    };
    this._reCaptchaRef = React.createRef();
  }
    handleChange = (e) =>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    captchaChange=(value)=> {
      this.setState({ value });
      if (value === null) this.setState({ expired: "true" });
      this.setState({
        captchaToken:value
      })
    }
 
 

    componentDidMount(){
      this._reCaptchaRef.current.execute();
    

    }

   
    render() {
   
        return (
            <>
            <div className="profile">
            <Header2/>
            <br/>
            <div className="Pycenter">
             
                <>
                 <div className="CardP">

<div className="row pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>
<div className="col-6 p-0">
<span style={{color:'#4f1da1',fontWeight:'700',fontSize:'22px'}}>Import Wallet</span>
</div>
<div className="col-6 p-0" style={{textAlign:'right'}}>
<span style={{color:'white',fontSize:'22px',fontWeight:'600'}}>Issuer : </span>
<span  style={{color:'#3F1585',fontSize:'22px',fontWeight:'600'}}> AIFI</span>
    </div>
</div>


<div className="row mt-5 ">
    <div className="col-md-6 col-sm-12">
    <form>
        <div className="form-group">
             <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Private Key</label>
             <input type="password" name="privateKey" onChange={this.handleChange} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your private key"/>

        </div>
    </form>
    </div>
    <div className="col-md-6 col-sm-12">
    <form>
    <div className="form-group">
     <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>Passphrase</label>
    <input type="password" name="password" onChange={this.handleChange} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your passphrase"/>
    </div>
    </form>
    </div>
</div>

<div className="row mt-3 ">
    <div className="col-md-6 col-sm-12">
    <form>
        <div className="form-group">
             <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Date</label>
             <div>


                 <Form.Group controlId="dob" style={{width:''}}>
                 <Form.Control type="" name="dob" placeholder={this.state.date} style={{width:'100%',cursor:''}} disabled/>
                </Form.Group>

  
             </div>

        </div>
    </form>
    </div>
    <div className="col-md-6 col-sm-12">
    <form>
    <div className="form-group">
     <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>Amount</label>
    <input type="" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={this.state.tokenDetails?this.state.tokenDetails.decodedTokenForFees.fees:''} disabled/>
    </div>
    </form>
    </div>
</div>
<br/>
<p style={{fontSize:'18px',marginBottom:'0px'}}>It may take a few moments for this transaction to appear in your account</p>
</div>

<center style={{marginTop:'40px'}}>

<button className="ButtonErc20" onClick={this.handleSubmit}>Finish and Pay    
<img  src={ercbtn} style={{height:'4vh',marginLeft:'20px'}}/>
</button>

{/* <p>{this.state.msg}</p> */}
{/* <br/> */}
<p style={{fontSize:'18px',fontWeight:'500',marginBottom:'0px',marginTop:'15px'}}>Need a new wallet? <a href="https://faucet.sharkwallet.app/" target='_blank' style={{color:'#4f1da1'}}><u>Create it here</u></a></p>
</center>


                </>
           
               
               
            </div>
            </div>
            <Footer/>
 
        
        <ReCAPTCHA
    sitekey="6LfdhT4pAAAAABS4bfqKSS3vByEJorSz-Aen3Aji"
    onChange={this.captchaChange}
    size="invisible"
    ref={this._reCaptchaRef}
 
  />
            </>
        )
    }
}