import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form, Row, Col , Navbar , Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import signin from '../Images/signin.png'
import Logo from '../Images/logo5.png'
import ercbtn from '../Images/ercbtn.png'
import gif from '../Images/sharkweb.gif'
import ReCAPTCHA from "react-google-recaptcha"
import axios from 'axios'
var CryptoJS = require("crypto-js");
export default class Signin extends Component {
    constructor(props, ...args) {
        super(props, ...args);
    this.state = {
        showPassword:'',
        email:'',
        password:'',
        signinMsg:'',
        wrongEmailMsg:'',
        wrongPass:'',
        msg:'',

        callback: "not fired",
        value: "[empty]",
        load: false,
        expired: "false",
        recaptchaLoaded: false,
        captchaToken:'',
    };
    this._reCaptchaRef = React.createRef();
}
captchaChange=(value)=> {
    this.setState({ value });
    if (value === null) this.setState({ expired: "true" });
    this.setState({
      captchaToken:value
    })
  }
    handleChange = (e) =>{
        this.setState({
        [e.target.name]:e.target.value
        })
      }
      handleEye = () =>{
        this.setState({
        showPassword:!this.state.showPassword
        })
      }
      signin = () =>{
        this.setState({
            msg:    <img src={gif} style={{height:'2rem',width:'2rem'}} alt="loader" />
        //    msg: <div style={{color:"#000"}} className="spinner-grow" role="status">
        //    <span className="sr-only">Loading...</span>
        //  </div>
        })
          if(this.state.email.length>3 && this.state.password.length>0){
               var encrypt1 = CryptoJS.AES.encrypt(this.state.email,"key")
               var encrypt2 = CryptoJS.AES.encrypt(this.state.password,"key")
            //    setTimeout(()=>{
               axios.post("https://deploy.sharkweb.com/api/signin",{
                   encrypt1:encrypt1.toString(),
                   encrypt2:encrypt2.toString(),
                   captchaToken:this.state.captchaToken,
   
               })
               .then(async(res)=>{
                //  console.log(res.data)
                this._reCaptchaRef.current.execute();
                if(res.data.token){
                    // console.log(res.data.token)
                    sessionStorage.setItem("token",res.data.token)
                    sessionStorage.setItem("email",res.data.email)
                    window.location.href="/createtoken"
                }
                else{
                    this.setState({
                        msg: res.data
                    })
                }
        //        this._reCaptchaRef.current.execute();
        //        if(res.data.user.email){
        //         sessionStorage.setItem("email",res.data.user.email)
        //         sessionStorage.setItem("hash",res.data.user.hashed_password)
        //         window.location.href="/"
        //         this.setState({
        //           disabledSignin:false,
        //           //  otpModal:true,
        //           //  tokenotp:res.data.token,
        //            msg:  ''
        //        })
        //     }
        //    else{
        //        this.setState({
        //            msg: res.data,
        //            disabledSignin:false
        //        })
        //    }
               })
            //   },1000)
   
           }
           else{
               this.setState({
                   msg: "Please enter all details"
               })
           }
     }
     componentDidMount(){
        this._reCaptchaRef.current.execute();
     }
    render() {
        // console.log(this.state)
        return (
            <>
<div>
    <div className="row w-100 m-0">
        <div className="col-md-3 col-lg-6 col-sm-12 p-0">
            {/* <img className="ImageH" src={signin} style={{height:'100vh'}}/> */}
            <div className="signin">
                <Link to='/'>
            <img src={Logo} style={{height:'6vh',cursor:"pointer"}}/></Link>
                <br/><br/>
                <center> 
                    <h1 className="sectionHeading">No-Code Token Generator</h1>
                    <br/>
                <p className="" style={{fontSize:'21px'}}>Create a Token in less than a minute with No-Code Token Generator. 
                <br/>
                No coding required.
                </p>
                <br/>
                <div style={{paddingTop:''}}>
    {/* <Link to="/signin">
<button className="ButtonErc20">
    Create a Token 
    <img  src={ercbtn} style={{height:'4vh',marginLeft:'20px'}}/>
</button>
</Link> */}
{/* 
<button className="ButtonPricing">Pricing</button> */}
                    </div>
                    </center>
            </div>
        </div>
        <div className="col-md-9 col-lg-6  col-sm-12 mobilePadZero p-0">
          <div className="Login">
            <center>
                <h1 style={{fontWeight:'700'}}>Login</h1>
                <p style={{fontSize:'19px',marginTop:'20px'}}>Login to access your account</p>
            </center>
            <br/>
            <br/>
            <form>
                <div className="form-group">
                 <label for="exampleInputEmail1" style={{fontSize:'19px',fontWeight:'600'}}>Email</label>
                 <input type="email" className="form-control Pform" name="email" onChange={this.handleChange}  id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"/>
                </div>
                <div className="form-group mt-4">
                 <label for="exampleInputEmail1 " style={{fontSize:'19px',fontWeight:'600'}}>Password</label>
                   <div className="input-group mb-3">

                    <input  name="password" 
                    type={this.state.showPassword?"text" : "password"} 
                    onChange={this.handleChange} 
                    className="form-control Pform" 
                    placeholder="***********" 
                    style={{borderRight:'none'}}
                    />

                    <div className="input-group-append">
                    <span className="input-group-text" id="basic-addon2" style={{backgroundColor:'transparent',borderLeft:'none',cursor:'pointer'}}> 
                    {
                    this.state.showPassword 
                    ?
                    <i className="fa fa-eye" onClick={this.handleEye} ></i>
                    : 
                   <i className="fa fa-eye-slash " aria-hidden="true" onClick={this.handleEye} style={{zIndex:"999"}}></i>
                    }
                    </span>
                    </div>
                </div>
                 </div>
            </form>

            <br/>
            {/* <Link to="/createtoken"> */}
            <button className="LoginButton" onClick={this.signin}>Login</button>
            <p className='text-center' style={{marginTop:'1rem'}}>{this.state.msg}</p>
           

            <div className="form-check" style={{marginTop:'30px'}}>
                <input className="form-check-input" style={{marginTop:"0.4rem"}} type="checkbox" value="" id="flexCheckDefault"/>
                <label className="form-check-label" for="flexCheckDefault">
                    Keep me logged in
                </label>
                <span style={{float:'right'}}>
                    <u style={{color:'#3F1585'}}>
                    <Link to="/forgot-password"  style={{color:'#3F1585'}}>Forgot Password ?</Link>
                    </u>
                </span>
            </div>
            
            <br/>
            <br/>
            <center>
                <p>Don't have an account? 
                    <Link to="/signup" style={{color:'#3F1585',marginLeft:'5px'}}>
                        <u>Sign up</u>
                    </Link>
                </p>
            </center>



          </div>
        </div>
    </div>
</div>
<ReCAPTCHA
    sitekey="6LfdhT4pAAAAABS4bfqKSS3vByEJorSz-Aen3Aji"
    onChange={this.captchaChange}
    size="invisible"
    ref={this._reCaptchaRef}
 
  />   
          
            </>
        )
    }
}