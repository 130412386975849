import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form, Row, Col , Navbar ,Modal, Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import Header2 from './Header/Header2';
import Footer from './Footer';
import axios from 'axios'
import './Api Keys/sidebar.css'
import ercbtn from '../Images/ercbtn.png'
import Logo from '../Images/logo5.png';
import close from '../Images/close.png';

export default class GenerateAp extends Component {

  constructor(props, ...args) {
    super(props, ...args);
  this.state={
    key_type:'',
    purpose:'',
    label:'',
    msg:'',
    token:'',
    email:''
  }
}
  componentDidMount = async()=>{
    let token = sessionStorage.getItem("token")
    let email = sessionStorage.getItem("email")
    if(!token || !email){
        window.location.href="/signin"
    }
    else{
      this.setState({
        token:token,
        email:email
      })
      const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
     await axios.get("https://deploy.sharkweb.com/api/validateToken",config)
      .then(res=>{
        if(res.data==='User validation failed'){
          sessionStorage.clear();
          window.location.href='/signin'
        }
      })
    }
  }
  handleKeyDown = (event) => {
    const forbiddenCharacters = [' ', '.', ',', '/','%','^','&','$','~','!','@','#']; // Characters not allowed
    if (forbiddenCharacters.includes(event.key)) {
      event.preventDefault(); // Prevent the default behavior of typing space
    }
  };
  
  handleChange = (e) =>{
    this.setState({
        [e.target.name]:e.target.value
    })
}
   nextPage = () =>{
    if(this.state.key_type.length>0 && this.state.label.length>0 && this.state.purpose.length>0){
       window.location.href=`/payment-api?&&${this.state.key_type}&&${this.state.purpose}&&${this.state.label}`
    }
    else{
        this.setState({
            msg:'Please enter all the details'
        })
    }
   }
render() {
    //  console.log(this.state)
        return (
            <>
            <div className="ApiHead" style={{backgroundColor:'#09091c',padding:'2%'}}>
            <Header2/>
            </div>
          <hr style={{backgroundColor:'#1c1b28',margin:'0px'}}/>
          <div className="contentApi">
          <div className="padLeftRight" style={{backgroundColor:'#0c0c1f',color:'#d2d2d3',paddingLeft:'15%',paddingRight:'15%',}}>
                       <p style={{color:'white',fontSize:'28px',fontWeight:'600'}}>Generate API Key</p>
                        <p style={{fontSize:'15px'}}>API keys allow you to access the SharkWeb API. Check out our <Link to="https://docs.sharkweb.com" target='_blank' style={{color:'#6f2baa'}}>Developer Help Center</Link>  for the
complete authentication guide.</p>
<div>
    <label style={{fontWeight:'600',color:'white'}}>API Key Type</label>
<select className="form-selec custom-select" aria-label="Default select example"  style={{ width: '100%',color:'white', backgroundColor: 'transparent', height: '5.5vh' ,border:'1px solid #34343d',fontSize:'15px',paddingLeft:'1.5%', 
        backgroundPosition: 'right 10px center',}}
        name="key_type"
        onChange={this.handleChange}
        >
<option selected>Client API Key </option>
  <option value="For_DAPPs">For DAPPs</option>
  {/* <option value="2">Two</option>
  <option value="3">Three</option> */}
                </select>
                <p style={{float:'right',fontSize:'13px',marginTop:'10px'}}>9 Client API Key(s) remaining</p>
</div>
{/* <div className="form-group mt-4">
    <label for="" style={{fontWeight:'600',color:'white'}}>Name</label>
    <input type="text" className="form-control pColor" id="" aria-describedby="" placeholder="Enter name" style={{color:'white', backgroundColor: 'transparent', height: '5.5vh' ,border:'1px solid #34343d',fontSize:'15px'}}/>
  
  </div> */}
  <div className="form-group mt-4">
    <label for="" style={{fontWeight:'600',color:'white'}}>Purpose</label>
    <select className="form-select custom-select" aria-label="Default select example"  style={{ width: '100%',color:'white', backgroundColor: 'transparent',paddingLeft:'2%', height: '5.5vh' ,border:'1px solid #34343d',fontSize:'15px',paddingLeft:'1.5%',
      WebkitAppearance:'none'}}
        name="purpose"
        onChange={this.handleChange}
        >
<option selected>Select Purpose</option>
  <option value="Dev_project">Dev project</option>
  <option value="Test_Project">Test Project</option>
  {/* <option value="3">Three</option> */}
                </select>
  </div>
  <div>
  <label className="mt-2" for="" style={{fontWeight:'600',color:'white'}}>Label</label>
    <input type="text" onChange={this.handleChange}onKeyDown={this.handleKeyDown} name="label" className="form-control pColor" id="" aria-describedby="" placeholder="Name your API" style={{color:'white', backgroundColor: 'transparent', height: '5.5vh' ,border:'1px solid #34343d',fontSize:'15px'}}/>

             </div>
          
             <div className="CardP mt-5">




<div className="row">
    <div className="col-md-6 col-sm-12">
             <p style={{fontSize:'20px',color:'#b8b8b8'}}>Commission Fee : <i className="fa fa-exclamation-circle" style={{color:'white'}}></i></p>
    </div>
    <div className="col-md-6 col-sm-12" style={{float:'right',textAlign:'right'}}>
     <button disabled style={{backgroundColor:"#3F1585",border:"none",borderRadius:'.25rem',color:'white',fontWeight:'500',minWidth:'7rem',minHeight:'5vh'}}>60 AIFI</button>
    </div>
</div>

{/* <div className="row mt-3 ">
    <div className="col-md-6 col-sm-12">
             <p style={{fontSize:'20px',color:'#b8b8b8'}}>Gas Fee : <i className="fa fa-exclamation-circle" style={{color:'white'}}></i></p>
    </div>
    <div className="col-md-6 col-sm-12" style={{float:'right',textAlign:'right'}}>
     <button style={{backgroundColor:"#29bd6d",border:"none",borderRadius:'.25rem',color:'white',fontWeight:'500',minWidth:'7rem',minHeight:'5vh',cursor:'pointer'}}>Variable</button>
    </div>
</div> */}

                </div>  
                <br/><br/>
             <center>
                        <button className="wAuto" variant="secondary" onClick={() => this.setState({ showModal: false })}
                        style={{minWidth:'48.7%',marginRight:'15px',border:'1px solid #3F1585',backgroundColor:'transparent',color:'white'}}>
                            Cancel
                        </button>
                        {/* <Link to="/payment-api"> */}
                        <button className="wAuto"  variant="secondary" onClick={this.nextPage}
                           style={{minWidth:'48.7%',marginRight:'',backgroundColor:'#3F1585',border:'1px solid #3F1585',fontWeight:'500'}}>
                            
                            Generate
                        </button>
                        {/* </Link> */}
                        <p style={{paddingTop:'1rem'}}>{this.state.msg}</p>
                        </center>
                    </div>
          </div>
              <div style={{ marginLeft: '' }}>
              <Footer />
              </div>
              
          </>
        )
    }
}