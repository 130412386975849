import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form, Row, Col ,Modal, Navbar , Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import Header from './Header/Header2';
import cross from '../Images/cross.png'
import Footer from './Footer';
import { Tune } from '@mui/icons-material';
export default class DeployedNft extends Component {

  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
        
        show:false

    }
  }

    handleClose = ()=>{
      this.setState({
        show:false
      })
    }

    handleTokenDetails=()=>{
        this.setState({
            show:true
          })
    }
    render() {
        return (
            <>

               <div className="Deployed">
                <Header/>
                <div style={{paddingTop:'3%'}}>
                <h1 className="Dfont" style={{fontWeight:'500',fontSize:'35px'}}>User Deployed NFT's</h1>
                <div className="table-responsive table-borderless" style={{backgroundColor:'#13132e',padding:'3%',marginTop:'3.5%'}}>
                <table className="table text-center">
  <thead>
    <tr>
      <th scope="col">Token Name</th>
      <th scope="col">Token Type</th>
      <th scope="col">Address</th>
      <th scope="col">Token Symbol</th>
    </tr>
  </thead>
  <tbody>
    <tr className="tableHover" style={{backgroundColor:'#1b1b3e'}} onClick={this.handleTokenDetails}>
      <td scope=""  className="tableHoverTd" style={{color:'#3d66c5'}}>Xyz Token</td>
      <td>Simple AIFI22</td>
      <td className="tableHoverTd ellipsis" style={{color:'#50a7df'}}>0xUZ1b76sa92</td>
      <td>xyzz</td>
    </tr>
    <tr className="tableHover"  style={{backgroundColor:'#202044'}} onClick={this.handleTokenDetails}>
    <td scope=""  className="tableHoverTd" style={{color:'#3d66c5'}}>Xyz Token</td>
      <td>Simple AIFI22</td>
      <td className="tableHoverTd ellipsis" style={{color:'#50a7df'}}>0xUZ1b76sa92</td>
      <td>xyzz</td>
    </tr>
    <tr className="tableHover" style={{backgroundColor:'#1b1b3e'}} onClick={this.handleTokenDetails}>
    <td scope=""  className="tableHoverTd" style={{color:'#3d66c5'}}>Xyz Token</td>
      <td>Simple AIFI22</td>
      <td className="tableHoverTd ellipsis" style={{color:'#50a7df'}}>0xUZ1b76sa92</td>
      <td>xyzz</td>
    </tr>
    <tr className="tableHover"  style={{backgroundColor:'#202044'}} onClick={this.handleTokenDetails}>
    <td scope=""  className="tableHoverTd" style={{color:'#3d66c5'}}>Xyz Token</td>
      <td>Simple AIFI22</td>
      <td className="tableHoverTd ellipsis" style={{color:'#50a7df'}}>0xUZ1b76sa92</td>
      <td>xyzz</td>
    </tr>
    <tr className="tableHover" style={{backgroundColor:'#1b1b3e'}} onClick={this.handleTokenDetails}>
    <td scope=""  className="tableHoverTd" style={{color:'#3d66c5'}}>Xyz Token</td>
      <td>Simple AIFI22</td>
      <td className="tableHoverTd ellipsis" style={{color:'#50a7df'}}>0xUZ1b76sa92</td>
      <td>xyzz</td>
    </tr>
  </tbody>
</table>
<center>
    <u>
    <Link to="" style={{color:'white'}}>See all</Link>
    </u>
</center>
                </div>
                </div>
            </div>

               <Footer/>

               <Modal show={this.state.show} onHide={this.handleClose} size='lg'>
       
      
          
        
        <Modal.Body  style={{padding:'6%',fontSize:'15px'}}>
            <Modal.Header style={{padding:'0px',borderBottom:'0px'}}>
            <Modal.Title style={{color:'#0b3281',fontWeight:'600'}}>Databse Relation</Modal.Title>
          {/* <i className="text-right fa fa-times" onClick={this.handleClose} style={{cursor:'pointer'}}></i> */}
          <img src={cross} className="cross" onClick={this.handleClose} style={{position:'absolute',right:'0',top:'0',height:'12.5vh',cursor:'pointer'}}/>
            </Modal.Header>
      
            <hr/>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>User ID</p>
                    </div>
                    <div className="col-6">
                        <p style={{fontWeight:'500',color:'#787878'}}>#04</p>
                        </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>Number of NFT</p>
                    </div>
                    <div className="col-6">
                        <p style={{fontWeight:'500',color:'#787878'}}>02</p>
                        </div>
                </div>
                </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>Object ID</p>
                    </div>
                    <div className="col-6">
                        <p style={{fontWeight:'500',color:'#787878'}}>xyz</p>
                        </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>Number</p>
                    </div>
                    <div className="col-6">
                        <p style={{fontWeight:'500',color:'#787878'}}>xyz</p>
                        </div>
                </div>
                </div>
          </div>

          <Modal.Header style={{padding:'0px',borderBottom:'0px',paddingTop:'5%'}}>
            <Modal.Title style={{color:'#0b3281',fontWeight:'600'}}>Blockchain</Modal.Title>
          
            </Modal.Header>
      
            <hr/>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>NFT token address</p>
                    </div>
                    <div className="col-6">
                        <p className="ellipsis" style={{fontWeight:'500',color:'#787878'}}>0xUZ1b76sa92</p>
                        </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>Owner address</p>
                    </div>
                    <div className="col-6">
                        <p className="ellipsis" style={{fontWeight:'500',color:'#787878'}}>0xUZ1b76sa92</p>
                        </div>
                </div>
                </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>Transaction address</p>
                    </div>
                    <div className="col-6">
                        <p className="ellipsis" style={{fontWeight:'500',color:'#787878'}}>0xUZ1b76sa92</p>
                        </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>Block number</p>
                    </div>
                    <div className="col-6">
                        <p style={{fontWeight:'500',color:'#787878'}}>956</p>
                        </div>
                </div>
                </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-6">
                        <p style={{fontWeight:'600'}}>Block hash</p>
                    </div>
                    <div className="col-6">
                        <p className="ellipsis" style={{fontWeight:'500',color:'#787878'}}>0xUZ1b76sa92</p>
                        </div>
                </div>
            </div>
            
          </div>

        </Modal.Body>
    </Modal>
            </>
        )
    }
}