import React, { Component } from 'react';
import '../../css/style.css';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import profile1 from '../../Images/p.png';
import Logo from '../../Images/Logo.png';
import Logout from '../../Images/logout.png';
export default class Header2 extends Component {
  state = {
    activePage:'',
    show:false,
    sharkwebWallet:false
  }
  componentDidMount(){
    var token = window.location.href.split('/')[3]
    this.setState({
      activePage : token
    })
  }
  logOut = () =>{
    sessionStorage. clear()
    window.location.href='/signin'
  }
  render() {
    return (
      <>
      <Navbar bg="transparent" expand="lg" className="p-0 " style={{marginTop:''}}>
        <>
          <Navbar.Brand href="/createtoken" style={{ fontSize: '22px', color: 'white' }}>
            <img src={Logo} style={{ height: '6vh' }} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" style={{backgroundColor:'white'}}/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
            <Nav.Link href="/user-deployed-token"  style={{marginRight:'30px',color:this.state.activePage==='user-deployed-token'?"#7739de":"white",minWidth:'',fontSize:'18px',fontWeight:this.state.activePage==='user-deployed-token'?"700":"600"}}>
            My Tokens
          </Nav.Link>
          {/* <Nav.Link href="/user-deployed-nft"  style={{marginRight:'30px',color:this.state.activePage==='user-deployed-nft'?"#3F1585":"white",minWidth:'',fontSize:'16px',fontWeight:this.state.activePage==='user-deployed-nft'?"700":"600"}}>
            My NFT's
          </Nav.Link> */}
          <Nav.Link href="/createtoken"  style={{marginRight:'30px',color:this.state.activePage==='createtoken'?"#7739de":"white",minWidth:'',fontSize:'18px',fontWeight:this.state.activePage==='createtoken'?"700":"600"}}>
          Create Token
          </Nav.Link>
          <Nav.Link href="/api-list" style={{marginRight:'30px',color:this.state.activePage==='api-list'?"#7739de":"white",minWidth:'',fontSize:'18px',fontWeight:this.state.activePage==='api-list'?"700":"600"}}>
          Api List
          </Nav.Link>
        
           <Nav.Link href="/generate-api-keys" style={{marginRight:'30px',color:this.state.activePage==='generate-api-keys'?"#7739de":"white",minWidth:'',fontSize:'18px',fontWeight:this.state.activePage==='generate-api-keys'?"700":"600"}}>
          Generate Api Keys
          </Nav.Link>
         
         
        
       
              
             
            
            </Nav>
            <Nav className="ml-auto ">
            <Link to="/profile" className="nav-link" style={{ fontSize: '20px', color: 'white' ,marginLeft:'5px'}}>
                 <img className="" src={profile1} style={{ height: '6vh' ,marginLeft:''}} alt="Profile" />
                </Link>
                <Link onClick={this.logOut} className="nav-link" >
                <img className="" src={Logout} style={{ height: '4.5vh' ,marginTop:'5px',marginLeft:'13px'}} alt="Logout" />
                </Link>
              </Nav>
          </Navbar.Collapse>
        </>
      </Navbar>
 
    </>
    );
  }
}
