import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form, Row, Col , Navbar , Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import Header2 from './Header/Header2';
import Footer from './Footer';
import ercbtn from '../Images/ercbtn.png'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import axios from 'axios'
import gif from '../Images/sharkweb.gif'
// import copy from "copy-to-clipboard";
export default class Mintable extends Component {
    constructor(props, ...args) {
        super(props, ...args);
   this.state={
        copyClipboard:'',
        email:'',
        token:'',
        tokenData:'',
        tokenAmt:'',
        pk:'',
        pp:'',
        remark:'',
        sender:'sharkweb',
        id:'',
        msg:'',
        disabled:false
    }
}

    componentDidMount(){
        let token = sessionStorage.getItem("token")
        let email = sessionStorage.getItem("email")
        const id = window.location.href.split('?')[1]
              if(!token || !email){
                  window.location.href="/signin"
              }
              else{
                this.setState({
                  token:token,
                  email:email,
                  id:id
                })
                const config = {
                  headers: { Authorization: `Bearer ${token}` }
              };
                axios.post("https://deploy.sharkweb.com/api/getUserDeployedTokenByID",{
                    email:email,
                    id:id
                },config)
                .then(res=>{
                    // console.log(res.data)
                    if(res.data==='Authorization failed'){
                        sessionStorage.clear();
                        window.location.href='/signin'
                    }
                    else if(res.data==='User validation failed'){
                        this.setState({
                            tokenData:'User validation failed'
                        })
                    } 
                    else{
                        this.setState({
                            tokenData:res.data,
                            remark:res.data.tokenName
                        })
                    } 
                })
              }
      }
      handleChange = (e) =>{
        this.setState({
        [e.target.name]:e.target.value
        })
      }
      mint = async() =>{
        this.setState({
            msg:<img src={gif} style={{height:'2rem',width:'2rem'}} alt="loader" />,
           disabled:true
          })
          if(this.state.tokenAmt.length>0 && this.state.pk.length>0 && this.state.pp.length>0 && this.state.remark.length>0){
            // console.log('Hello')
            const config = {
                headers: { Authorization: `Bearer ${this.state.token}` }
            };
            await axios.post("https://deploy.sharkweb.com/api/mintToken",{
                email:this.state.email,
                id:this.state.id,
                privatekey:this.state.pk,
                pass:this.state.pp,
                amount:this.state.tokenAmt, 
                remark:this.state.remark,
                sender:'sharkweb'
            },config)
            .then(res=>{
                // console.log(res.data)

                if(res.data?.note === 'Transaction created and broadcast successfully.'){
                    this.setState({
                        msg:`txn : ${res.data?.TxnData.transactionId}`,
                        disabled:false
                    })
                }else{
                    this.setState({
                        msg:res.data,
                        disabled:false
                    })
                }
            })
            .catch(error=>{
                if(error){
                    this.setState({
                        msg:'Try again',
                        disabled:false
                    })
                }
            })
          }
          else{
              this.setState({
                  msg:"Please enter all details",
                  disabled:false
              })
          }
      }

            copyAddress=()=>{
                        // copy(this.state.address);
                        this.setState({
                        copyClipboard:'Copied to clipboard!'
                        })
                        // console.log(this.copyAddress)
                    }
           
 
    render() {
        //    console.log(this.state)
        return (
           
            <>
            <div className="profile">
            <Header2/>
            <br/>
            <h1 className="token">Blockchain</h1>
            <div className="cardWhite" style={{borderRadius:'.25rem',paddingLeft:'3%',paddingRight:'3%',paddingTop:'1%',paddingBottom:'1%',fontSize:'18px',color:'black'}}>
            <i className="fa fa-exclamation-triangle" style={{color:'red',fontSize:'20px',marginRight:'10px'}}></i>
            <span> You must have a wallet created before creating token using this platform. Please visit<Link to={'https://faucet.sharkwallet.app/'} target='_blank'> https://faucet.sharkwallet.app/ </Link> for wallet creation.
</span>
            </div>
            <div className="Pycenter">
                {this.state.tokenData!='User validation failed'?
                <>
                <div className="CardP">
                    <div className=" pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>
                    <span style={{color:'#3F1585',fontWeight:'700',fontSize:'32px'}}>Mint</span>
                    </div>
                    <div className="row mt-5 ">
                        <div className="col-md-6 col-sm-12">
                        <form>
                            <div className="form-group">
                                 <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>No. of tokens</label>
                                 <input type="" name="tokenAmt" onChange={this.handleChange} className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Enter no. of tokens you want to mint"/>
                                    {/* <p className="small" style={{color:'#707384'}}>Choose a name for NFT</p> */}
                            </div>
                        </form>
                        </div>
                        {/* <div className="col-md-6 col-sm-12">
                        <form>
                        <div className="form-group">
                         <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>Amount</label>
                         <input type="" className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Enter amount"/>
                         </div>
                        </form>
                        </div> */}
                        <div className="col-md-6 col-sm-12">
                        <form>
                            <div className="form-group">
                                 <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Private Key</label>
                                 <input type="password" name="pk" onChange={this.handleChange} className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Enter your private key"/>
                                 {/* <i className='test1' onClick={this.copyAddress}></i> */}
                                    {/* <p className="small" style={{color:'#707384'}}>Choose a name for NFT</p> */}
                            </div>
                        </form>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <form>
                        <div className="form-group">
                         <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>Passphrase</label>
                         <input type="password" name="pp" onChange={this.handleChange} className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Enter your passphrase"/>
                         {/* <p className="small"  style={{color:'#707384'}}>Choose a symbol for your token </p> */}
                         </div>
                        </form>
                        </div>
                    </div>
 
                    <div className=" pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>
                    <span style={{color:'#3F1585',fontWeight:'700',fontSize:'32px'}}>Transactions Details</span>
                </div>
 
                <div className="row mt-5 ">
                    <div className="col-md-6 col-sm-12">
                        <p style={{fontSize:'20px',color:'#b8b8b8'}}>Commission Fee : <i className="fa fa-exclamation-circle" style={{color:'white'}}></i></p>
                    </div>
                    <div className="col-md-6 col-sm-12" style={{float:'right',textAlign:'right'}}>
                    <button disabled style={{backgroundColor:"#3F1585",border:"none",borderRadius:'.25rem',color:'white',fontWeight:'500',minWidth:'7rem',minHeight:'5vh'}}>40 AIFI</button>
                    </div>
                </div>
 
                {/* <div className="row mt-3 ">
                    <div className="col-md-6 col-sm-12">
                    <p style={{fontSize:'20px',color:'#b8b8b8'}}>Gas Fee : <i className="fa fa-exclamation-circle" style={{color:'white'}}></i></p>
                    </div>
                    <div className="col-md-6 col-sm-12" style={{float:'right',textAlign:'right'}}>
                    <button style={{backgroundColor:"#29bd6d",border:"none",borderRadius:'.25rem',color:'white',fontWeight:'500',minWidth:'7rem',minHeight:'5vh',cursor:'pointer'}}>Variable</button>
                    </div>
                </div> */}
                {this.state.disabled?
                  <button className="button-ptm" disabled style={{backgroundColor:'gray'}}>Proceed to Mint</button>
                  :
                <button onClick={this.mint} className="button-ptm">Proceed to Mint</button>
                 }
                 <p className='text-center nt-2' onClick={()=>this.state.msg==='Try again'?window.location.reload():''} style={{color:this.state.msg==='Try again'?"red": "#fff",marginTop:'1rem',cursor:'pointer'}}>{this.state.msg}</p>
                </div>
               
 
               {/* <div className="CardP">
                    <div className=" pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>
                    <span style={{color:'#3F1585',fontWeight:'700',fontSize:'32px'}}>Burnable</span>
                    </div>
                    <div className="row mt-5 ">
                        <div className="col-md-6 col-sm-12">
                        <form>
                            <div className="form-group">
                                 <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>No. of tokens</label>
                                 <input type="" className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Enter no. of tokens you want to burn"/>
                                    
                            </div>
                        </form>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <form>
                        <div className="form-group">
                         <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>Amount</label>
                         <input type="" className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Enter amount"/>
                        
                         </div>
                        </form>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <form>
                            <div className="form-group">
                                 <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Private Key</label>
                                 <input type="" className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Enter your private key"/>
                                 <i className='test1' onClick={this.copyAddress}></i>
                            </div>
                        </form>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <form>
                        <div className="form-group">
                         <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>Passphrase</label>
                         <input type="" className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Enter your passphrase"/>
                       
                         </div>
                        </form>
                        </div>
                    </div>  
                    <div className=" pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>
                    <span style={{color:'#3F1585',fontWeight:'700',fontSize:'32px'}}>Transactions Details</span>
                </div>
 
                <div className="row mt-5 ">
                    <div className="col-md-6 col-sm-12">
                        <p style={{fontSize:'20px',color:'#b8b8b8'}}>Commission Fee : <i className="fa fa-exclamation-circle" style={{color:'white'}}></i></p>
                    </div>
                    <div className="col-md-6 col-sm-12" style={{float:'right',textAlign:'right'}}>
                    <button style={{backgroundColor:"#3F1585",border:"none",borderRadius:'.25rem',color:'white',fontWeight:'500',minWidth:'7rem',minHeight:'5vh',cursor:'pointer'}}>40 AIFI</button>
                    </div>
                </div>
 
                <div className="row mt-3 ">
                    <div className="col-md-6 col-sm-12">
                    <p style={{fontSize:'20px',color:'#b8b8b8'}}>Gas Fee : <i className="fa fa-exclamation-circle" style={{color:'white'}}></i></p>
                    </div>
                    <div className="col-md-6 col-sm-12" style={{float:'right',textAlign:'right'}}>
                    <button style={{backgroundColor:"#29bd6d",border:"none",borderRadius:'.25rem',color:'white',fontWeight:'500',minWidth:'7rem',minHeight:'5vh',cursor:'pointer'}}>Variable</button>
                    </div>
                </div>
                <button className="button-ptm">Proceed to Burn</button>
               </div> */}
 
 
               {/* <div className="CardP">
                    <div className=" pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>
                    <span style={{color:'#3F1585',fontWeight:'700',fontSize:'32px'}}>Transferrable</span>
                    </div>
                    <div className="row mt-5 ">
                        <div className="col-md-6 col-sm-12">
                        <form>
                            <div className="form-group">
                                 <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Wallet Address</label>
                                 <input type="" className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Enter wallet address"/>
                                 
                            </div>
                        </form>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <form>
                        <div className="form-group">
                         <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>Private Key</label>
                         <input type="" className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Enter your private key"/>
                        
                         </div>
                        </form>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <form>
                            <div className="form-group">
                                 <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Passphrase</label>
                                 <input type="" className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Enter your passphrase"/>
                                 <i className='test1' onClick={this.copyAddress}></i>
                               
                            </div>
                        </form>
                        </div>
                    </div>  
                    <div className=" pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>
                    <span style={{color:'#3F1585',fontWeight:'700',fontSize:'32px'}}>Transactions Details</span>
                </div>
 
                <div className="row mt-5 ">
                    <div className="col-md-6 col-sm-12">
                        <p style={{fontSize:'20px',color:'#b8b8b8'}}>Commission Fee : <i className="fa fa-exclamation-circle" style={{color:'white'}}></i></p>
                    </div>
                    <div className="col-md-6 col-sm-12" style={{float:'right',textAlign:'right'}}>
                    <button style={{backgroundColor:"#3F1585",border:"none",borderRadius:'.25rem',color:'white',fontWeight:'500',minWidth:'7rem',minHeight:'5vh',cursor:'pointer'}}>40 AIFI</button>
                    </div>
                </div>
 
                <div className="row mt-3 ">
                    <div className="col-md-6 col-sm-12">
                    <p style={{fontSize:'20px',color:'#b8b8b8'}}>Gas Fee : <i className="fa fa-exclamation-circle" style={{color:'white'}}></i></p>
                    </div>
                    <div className="col-md-6 col-sm-12" style={{float:'right',textAlign:'right'}}>
                    <button style={{backgroundColor:"#29bd6d",border:"none",borderRadius:'.25rem',color:'white',fontWeight:'500',minWidth:'7rem',minHeight:'5vh',cursor:'pointer'}}>Variable</button>
                    </div>
                </div>
                <button className="button-ptm">Transfer now</button>
               </div> */}
 
               {/* <div className="CardP">
                    <div className=" pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>
                    <span style={{color:'#3F1585',fontWeight:'700',fontSize:'32px'}}>Pausable</span>
                    </div>
                    <div className="row mt-5 ">
                        <div className="col-md-6 col-sm-12">
                        <form>
                            <div className="form-group">
                                 <label for="exampleInputEmail1" style={{fontSize:'20px',fontWeight:'500'}}>Private Key</label>
                                 <input type="" className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Enter your private key"/>
                                 
                            </div>
                        </form>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <form>
                        <div className="form-group">
                         <label for="exampleInputEmail1"  style={{fontSize:'20px',fontWeight:'500'}}>Passphrase</label>
                         <input type="" className="form-control tokenI" id="exampleInputEmail1 tokenI" aria-describedby="emailHelp" placeholder="Enter your Passphrase"/>
                       
                         </div>
                        </form>
                        </div>
                       
                    </div>  
                    <div className=" pl-0 pr-0 pb-4" style={{borderBottom:'1px solid white'}}>
                    <span style={{color:'#3F1585',fontWeight:'700',fontSize:'32px'}}>Transactions Details</span>
                </div>
 
                <div className="row mt-5 ">
                    <div className="col-md-6 col-sm-12">
                        <p style={{fontSize:'20px',color:'#b8b8b8'}}>Commission Fee : <i className="fa fa-exclamation-circle" style={{color:'white'}}></i></p>
                    </div>
                    <div className="col-md-6 col-sm-12" style={{float:'right',textAlign:'right'}}>
                    <button style={{backgroundColor:"#3F1585",border:"none",borderRadius:'.25rem',color:'white',fontWeight:'500',minWidth:'7rem',minHeight:'5vh',cursor:'pointer'}}>40 AIFI</button>
                    </div>
                </div>
 
                <div className="row mt-3 ">
                    <div className="col-md-6 col-sm-12">
                    <p style={{fontSize:'20px',color:'#b8b8b8'}}>Gas Fee : <i className="fa fa-exclamation-circle" style={{color:'white'}}></i></p>
                    </div>
                    <div className="col-md-6 col-sm-12" style={{float:'right',textAlign:'right'}}>
                    <button style={{backgroundColor:"#29bd6d",border:"none",borderRadius:'.25rem',color:'white',fontWeight:'500',minWidth:'7rem',minHeight:'5vh',cursor:'pointer'}}>Variable</button>
                    </div>
                </div>
                <button className="button-ptm">Pause</button>
               </div> */}
                </>
                :
                <p className='text-center mt-3' style={{fontWeight:'800'}}>User validation failed</p>
                }
            </div>
            </div>
            <Footer/>
 
         
            </>
        )
    }
}