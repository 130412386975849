import React, { Component } from 'react'
import '../css/style.css'
import { Link } from 'react-router-dom';
import { Card, Button, Table, Form, Row, Col , Navbar , Container,NavDropdown,Nav, Carousel} from 'react-bootstrap';
import signin from '../Images/signin.png'
import Logo from '../Images/logo5.png'
import ercbtn from '../Images/ercbtn.png'
import axios from 'axios'
import ReCAPTCHA from "react-google-recaptcha"
import gif from '../Images/sharkweb.gif'
var CryptoJS = require("crypto-js");

export default class ForgotPassword extends Component {
    constructor(props, ...args) {
        super(props, ...args);
      this.state={
        callback: "not fired",
        value: "[empty]",
        load: false,
        expired: "false",
        recaptchaLoaded: false,
        captchaToken:'',

        forgotPassMsg:'',
        email:''
      };
      this._reCaptchaRef = React.createRef();
    } 
    captchaChange=(value)=> {
        this.setState({ value });
        if (value === null) this.setState({ expired: "true" });
        this.setState({
          captchaToken:value
        })
      }
    componentDidMount(){
    
        this._reCaptchaRef.current.execute();
    }
    forgotPass = () =>{
      this.setState({
          forgotPassMsg:<img src={gif} style={{height:'2rem',width:'2rem'}} alt="loader" />
      })
      if(this.state.email.length>0){
          axios.post("https://deploy.sharkweb.com/api/forgotPassword",{
              email:this.state.email,
              captchaToken:this.state.captchaToken
          })
          .then(res=>{
            this._reCaptchaRef.current.execute();
            if(res.data==='Invalid captcha'){
              this.setState({
                forgotPassMsg:res.data
              })
            }
            else if(res.data==='Email not registered'){
              this.setState({
                forgotPassMsg:res.data
            })
            }
            else{
              this.setState({
                forgotPassMsg:res.data
            })
            sessionStorage.setItem("reset_email",this.state.email);
            window.location.href="/password-recovery"
            }
          })
      }
      else{
          this.setState({
              forgotPassMsg:"Please make sure you have entered correct email"
          })
      }
     
  }
  handleChange = (e) =>{
    this.setState({
    [e.target.name]:e.target.value
    })
  }

    render() {
    
        return (
            <>
<div>
    <div className="row w-100 m-0">
        <div className="col-md-3 col-lg-6 col-sm-12 p-0">

            <div className="ForgotPass">
       
             




                        </div>
        </div>
        <div className="col-md-9 col-lg-6  col-sm-12 mobilePadZero p-0">
          <div className="Login" style={{paddingTop:'30%'}}>
            
          
               
                <h1 style={{fontWeight:'700',fontSize:'35px'}}>Forgot Password ?</h1>
                <p style={{fontSize:'',marginTop:'20px'}}>Enter the email address associated with your account
                <br/> and we'll sned you a link to reset you password</p>
      

            
            <br/>

            <div style={{width:'70%'}}>
                <div className="form-group">
                 <label for="exampleInputEmail1" style={{fontSize:'19px',fontWeight:'600'}}>Email*</label>
                 <input type="email" className="form-control mt-2 pp" name="email" onChange={this.handleChange}  id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your email" style={{borderRadius:'1.5rem',minHeight:'6vh'}}/>

                </div>
       
            {/* <Link to="/password-recovery"> */}
            <button onClick={this.forgotPass} className="LoginButton mt-2" style={{borderRadius:'1.5rem',minHeight:'6vh'}}>Continue</button>
            <center className='mt-2'><p>{this.state.forgotPassMsg}</p></center>
            {/* </Link> */}
             
            </div>

           
         



          </div>
        </div>
    </div>
</div>
            
           
<ReCAPTCHA
    sitekey="6LfdhT4pAAAAABS4bfqKSS3vByEJorSz-Aen3Aji"
    onChange={this.captchaChange}
    size="invisible"
    ref={this._reCaptchaRef}
 
  />
            </>
        )
    }
}